import { makeAutoObservable } from "mobx"

interface BaseAlert {
    message: string
    display: boolean
    title:   string
    icon: string
  }

  export interface Success extends BaseAlert {
    kind: 'success'
  }

  export interface Info extends BaseAlert {
    kind: 'info'
  }

  export interface Warn extends BaseAlert {
    kind: 'warning'
  }

  export interface Error extends BaseAlert {
    kind: 'error'
  }

  export type Alert = Success | Info | Warn | Error;

  export const alert = (message: string, kind: Alert['kind'], title='', icon=''): Alert =>
    ({
      message,
      kind,
      display: true,
      title,
      icon
    } as Alert)


export interface AlertStoreModel {
    getAlert: () => Alert | null
    push: (alert: Alert) => void
    process: () => void
    setDisplay: (alert: Alert, display: boolean) => void
}

class alertStore {
    alerts: Alert[] = []

    constructor() {
        makeAutoObservable(this)
    }

    getAlert = () => {
        if(this.alerts.length) return this.alerts[0]
        else return null
    }

    process = () => {
        this.alerts = this.alerts.slice(1);
    }

    push = (alert: Alert) => {
      // prevent two warnings with the same content from being displayed
      if(!this.alerts.some(el => el.message === alert.message && el.title === el.title)) {
        this.alerts.push(alert);
      }
    }

    setDisplay(alert: Alert, display: boolean) {
      alert.display = display
    }

}

const exportAlertStore = new alertStore()
export default exportAlertStore
