import { observer } from "mobx-react-lite"
import { UIEvent, useLayoutEffect } from "react"
import { t } from "i18next"
import { navigate } from "wouter/use-location"
import { useStore } from "../../../contexts/store"
import { ConversationItem } from "../../../../../types/Miscs"
import ConversationListItem from "./ConversationListItem"
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"

const ConversationList = ({podId, activeConversationId}: {podId:string, activeConversationId: string | undefined}) => {
  const { uiStore, sessionStore, podStore } = useStore()
  const userId = sessionStore.session.user.userId
  const showUnreads = uiStore.showUnreads
  // load list of conversations in which the user is involved
  let conversationList = podStore.getConversations(userId)

  useLayoutEffect(() => {
    const scrollPos = uiStore.getPodScrollPos(podId, "conversations")
    const podContainer = document.getElementById("conversation-list")
    if(scrollPos !== null && podContainer) {
      podContainer.scroll({
        top: scrollPos
      })
    }
  })

  if(!userId || conversationList === null) return null

  // sort conversation list by time of last written message
  conversationList.sort((a: ConversationItem, b: ConversationItem) => {
    if (a.tLastMessage > b.tLastMessage) return -1
    if (a.tLastMessage < b.tLastMessage) return 1
    return 0
  })
  // when selected show unread
  if(showUnreads) {
    conversationList = conversationList.filter(conversation => conversation.hasUnreadMessages || conversation.interactionId === activeConversationId)
  }

  const changeActiveConversation = (interactionId: string | null) => {
    uiStore.setActiveConversation(podId, interactionId)
    const url = `/pod/${podId}/conversations${interactionId ? `/${interactionId}` : ""}`
    navigate(url, { replace: true })
  }

  const updateScrollPos = (event: UIEvent<HTMLDivElement>) => {
    if(event && event.currentTarget && event.currentTarget.scrollTop !== undefined) {
      const scrollTop = event.currentTarget.scrollTop
      uiStore.setPodScrollPos(podId, "conversations", scrollTop)
    }
  }

  const UnreadsButton = () => (
    <ToggleButtonGroup
      color="primary"
      value={showUnreads ? "unreads" : "all"}
      exclusive
      onChange={(_, newAlignment: string) => {newAlignment === "unreads" ? uiStore.setShowUnreads(true) : uiStore.setShowUnreads(false)}}
      aria-label="Platform"
      sx={{margin: "4px 2px"}}
    >
      <ToggleButton value={"all"}>{t('All your Conversations')}</ToggleButton>
      <ToggleButton value={"unreads"}>{t('Unreads')}</ToggleButton>
    </ToggleButtonGroup>
  )

  return (
    <Box className="overflowWrapper" sx={{alignContent: "start"}}>
      {/* List Header */}
        <Box sx={{
            backgroundColor: "#edededdb",
            borderBottom: "1px solid rgba(63, 67, 80, 0.16)",
            borderLeft: "1px solid rgba(63, 67, 80, 0.16)",
            borderRight: "1px solid rgba(63, 67, 80, 0.16)",
          }}>
            <UnreadsButton />
        </Box>
      {/* List Content */}
      <Box id="conversation-list" onScroll={(event) => updateScrollPos(event)} sx={{display: "grid", borderRight: "1px solid rgba(63, 67, 80, 0.16)", overflowY: "auto"}}>
        {conversationList.map((conversation) =>
          <Box key={conversation.interactionId} onClick={() => changeActiveConversation(conversation.interactionId)}>
            <ConversationListItem item={conversation} podId={podId} activeConversationId={activeConversationId} />
          </Box>
        )}
        {conversationList.length === 0 &&
          <Box sx={{display: "grid", justifyContent: "center", color: "#9a9a9a", marginTop: "20px"}}>
            {t('no items existing')}
          </Box>
        }
      </Box>
    </Box>
  )
}


export default observer(ConversationList)
