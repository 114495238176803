import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import { InteractionType, Rect, iAnnotation, iComment, iEmotion, iLink, iReadingQuestion, iTag, iWeblink } from '../../../../types/Interaction'
import { OpCode } from '../../../../types/OpCodes'
import { ToolRepresentation, useParams } from '../../stores/uiStore'
import { opCodeToInteractionType } from '../../helper/Helper'
import OverlayFreehandBox from './OverlayFreehandBox'

const Overlay = (
    {
      childrenDepth,
      interactionEditId,
      pageId,
      pdfId,
      pageNumber,
      scale,
      hideFreehandBox,
    }: {
      childrenDepth: number,
      interactionEditId?: string,
      pageId: string,
      pdfId: string,
      pageNumber: number,
      scale: number,
      hideFreehandBox?: boolean
    }
) => {
  const { podStore, uiStore } = useStore()
  const { interactionId } = useParams()
  const [ pageContainer, setPageContainer ] = useState<any>(null)
  const hideInteractions = uiStore.hideInteractions
  const transformInteractionId = uiStore.transformInteractionMenu.interactionId
  const previewColor = uiStore.previewColor
  // highlight selected interaction
  let highlightInteractionId = interactionId
  if(transformInteractionId) highlightInteractionId = transformInteractionId

  useEffect(() => {
    const pageRef = document.getElementById(pageId)
    if(pageContainer === null && pageRef) setPageContainer(pageRef)
  }, [pageContainer, pageId])

  // test if page exists
  if(pageContainer === null || pageContainer.children[childrenDepth] === null) return null

  // Do not show interactions while a selection is in progress (otherwise they will end up in the rectangle list when crossing page boundaries)
  if ((uiStore.selectionStartRange) && (uiStore.selectionEndRange) && uiStore.selectionStartRange.pageNumber !== uiStore.selectionEndRange.pageNumber) return null

  // get page inside pdf container
  const page = pageContainer.children[childrenDepth]

  // save all associated rects for each interaction
  const overlayList: {rect: Rect, color: string, toolRepresentation: ToolRepresentation, interactionId: string}[] = []

  // edit view: show only the currently selected edit overlay or the currently selected create link overlay
  // otherwise: show overlay of all interactions
  if(interactionEditId) {
    if(interactionEditId === "addLink") {
      const addLinkAnchor = uiStore.selectedLinkAnchor
      if(addLinkAnchor) {
        const rects = addLinkAnchor.rects
        const toolRepresentation = uiStore.getToolRepresentation("link", addLinkAnchor.tool)
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: uiStore.getInteractionColor("addLink"), toolRepresentation: toolRepresentation, interactionId: interactionEditId})
          })
        }
      }
    } else {
      const interaction = podStore.getInteraction(pdfId, interactionEditId)
      const interactionEditAnchor = podStore.getInteractionEditAnchor(interactionEditId)

      if(interaction) {
        // check if interaction is transformed and change its interactionType
        const isTransformation = uiStore.interactionModal.isTransformation
        const selectedTransformOperation = uiStore.getSelectedOperation()
        const interactionType = isTransformation ? opCodeToInteractionType(selectedTransformOperation) : interaction.interactionType

        // get interaction color
        let interactionColor = uiStore.getInteractionColor(isTransformation ? selectedTransformOperation as OpCode : interaction.interactionType as InteractionType)
        if((isTransformation && selectedTransformOperation === "addAnnotation" && interaction.style.color) ||
           (!isTransformation && interaction.interactionType === 'annotation' && interaction.style.color)) {
          interactionColor = interaction.style.color
          // show preview color, if selected
          if(previewColor && previewColor.interactionId === interaction.interactionId) {
            interactionColor = previewColor.color
          }
        }

        // if edited text exists, highlight it. otherwise highlight existing text of the interaction
        let rects = interaction.anchor.rects
        let toolRepresentation = uiStore.getToolRepresentation(interactionType, interaction.anchor.tool)
        if(interactionEditAnchor && interactionEditAnchor.rects) {
          rects = interactionEditAnchor.rects
          toolRepresentation = uiStore.getToolRepresentation(interactionType, interactionEditAnchor.tool)
        }
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: interactionColor, toolRepresentation: toolRepresentation, interactionId: interactionEditId})
          })
        }
      }
    }
  } else {
    // load annotations for actual page
    const annotations: iAnnotation[] | null = hideInteractions['annotation'] ? null : podStore.getAnnotations(pdfId, pageNumber)
    // load comments for actual page
    const comments: iComment[] | null       = hideInteractions['comment'] ? null : podStore.getComments(pdfId, pageNumber)
    // load links for actual page
    const links: iLink[] | null             = hideInteractions['link'] ? null : podStore.getLinks(pdfId, pageNumber)
    // load tags for actual page
    const tags: iTag[] | null               = hideInteractions['tagging'] ? null : podStore.getTags(pdfId, pageNumber)
    // load weblinks for actual page
    const weblinks: iWeblink[] | null       = hideInteractions['weblink'] ? null : podStore.getWeblinks(pdfId, pageNumber)
    // load emotions for actual page
    const emotions: iEmotion[] | null       = hideInteractions['emotion'] ? null : podStore.getEmotions(pdfId, pageNumber)
    // load readingQuestions for actual page
    const readingQuestions: iReadingQuestion[] | null = hideInteractions['readingQuestion'] ? null : podStore.getReadingQuestions(pdfId, pageNumber)

    // read all associated rectangles for each annotation
    if(Array.isArray(annotations) && annotations.length) {
      annotations.forEach( annotation => {
        const color = annotation.style.color ? annotation.style.color : uiStore.getInteractionColor("annotation")
        const rects = annotation.anchor.rects
        const toolRepresentation = uiStore.getToolRepresentation("annotation", annotation.anchor.tool)
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, toolRepresentation: toolRepresentation, interactionId: annotation.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each comment
    if(Array.isArray(comments) && comments.length) {
      comments.forEach( comment => {
        const color = uiStore.getInteractionColor("comment")
        const rects = comment.anchor.rects
        const toolRepresentation = uiStore.getToolRepresentation("comment", comment.anchor.tool)
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, toolRepresentation: toolRepresentation, interactionId: comment.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each link
    if(Array.isArray(links) && links.length) {
      links.forEach( link => {
        const color = uiStore.getInteractionColor("link")
        const rects = link.anchor.rects
        const toolRepresentation = uiStore.getToolRepresentation("link", link.anchor.tool)
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, toolRepresentation: toolRepresentation, interactionId: link.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each tag
    if(Array.isArray(tags) && tags.length) {
      tags.forEach( tag => {
        const color = uiStore.getInteractionColor("tagging")
        const rects = tag.anchor.rects
        const toolRepresentation = uiStore.getToolRepresentation("tagging", tag.anchor.tool)
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, toolRepresentation: toolRepresentation, interactionId: tag.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each weblink
    if(Array.isArray(weblinks) && weblinks.length) {
      weblinks.forEach( weblink => {
        const color = uiStore.getInteractionColor("weblink")
        const rects = weblink.anchor.rects
        const toolRepresentation = uiStore.getToolRepresentation("weblink", weblink.anchor.tool)
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, toolRepresentation: toolRepresentation, interactionId: weblink.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each emotion
    if(Array.isArray(emotions) && emotions.length) {
      emotions.forEach( emotion => {
        const color = uiStore.getInteractionColor("emotion")
        const rects = emotion.anchor.rects
        const toolRepresentation = uiStore.getToolRepresentation("emotion", emotion.anchor.tool)
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, toolRepresentation: toolRepresentation, interactionId: emotion.interactionId})
          })
        }
      })
    }

    // read all associated rectangles for each readingQuestion
    if(Array.isArray(readingQuestions) && readingQuestions.length) {
      readingQuestions.forEach( readingQuestion => {
        const color = uiStore.getInteractionColor("readingQuestion")
        const rects = readingQuestion.anchor.rects
        const toolRepresentation = uiStore.getToolRepresentation("readingQuestion", readingQuestion.anchor.tool)
        if(Array.isArray(rects) && rects.length) {
          rects.forEach((rect: Rect) => {
            if(rect.p === pageNumber) overlayList.push({rect: rect, color: color, toolRepresentation: toolRepresentation, interactionId: readingQuestion.interactionId})
          })
        }
      })
    }

  }

  // if there are no overlays, only provide the overlay for freehand mode
  if (overlayList.length < 1) {
    return (
      createPortal(
        <div id={`overlay-page-${pageNumber}`}>
          <OverlayFreehandBox pageNumber={pageNumber} hideFreehandBox={hideFreehandBox} />
        </div>
      ,page
      )
    )
  }

  return (
    createPortal(
      <div id={`overlay-page-${pageNumber}`}>
        {overlayList.map((item: {rect: Rect, color: string, toolRepresentation: ToolRepresentation, interactionId: string}, index: number) => {
          if(item.toolRepresentation === "block") {
            return (
              <div key={index} style={{
                backgroundColor: uiStore.getSemiTransparentColor(item.color),
                height: item.rect.h * scale,
                left: item.rect.x * scale,
                opacity: (highlightInteractionId && item.interactionId !== highlightInteractionId) ? "20%" : "",
                position: "absolute",
                top: item.rect.y * scale,
                width: item.rect.w * scale,
                zIndex: 1,
              }}></div>
            )
          }
          else if(item.toolRepresentation === "underline")
          {
            return (
              <div key={index} style={{
                borderBottom: `2px solid ${item.color}`,
                height: item.rect.h * scale,
                left: item.rect.x * scale,
                opacity: (highlightInteractionId && item.interactionId !== highlightInteractionId) ? "20%" : "",
                position: "absolute",
                top: (item.rect.y * scale) + 1,
                width: item.rect.w * scale,
                zIndex: 2,
              }}></div>
            )
          }
          return null
        })}
        <OverlayFreehandBox pageNumber={pageNumber} hideFreehandBox={hideFreehandBox} />
      </div>
      ,page
    )
  )
}

export default observer(Overlay)
