import { t } from 'i18next'
import { MouseEvent, useState } from 'react'
import EmojiPickerElement from './EmojiPickerElement'

import { Button, IconButton, Menu, Tooltip } from '@mui/material'
import AddReactionIcon from '@mui/icons-material/AddReactionOutlined'

const EmojiPicker = ({handleEmojiClick, label}: {handleEmojiClick: (event: any) => void, label?: string | null}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  function onEmojiClick(event: any) {
    handleEmojiClick(event)
    handleClose()
  }

  return (
    <div>
        {label ?
          <Button
            onClick={handleClick}
            endIcon={<AddReactionIcon />}
            variant="text"
          >
            {label}
          </Button>
          :
          <Tooltip title={t('Add a reaction')}>
          <Button
            onClick={handleClick}
            variant="text"
            sx={{
              backgroundColor: "whitesmoke",
              border: "1px solid whitesmoke",
              color: "grey",
              height: "33.5px",
              minWidth: "30px",
              "&:hover": {
                backgroundColor: "white",
                border: "1px solid lightgrey"
              }
            }}
          >
            <AddReactionIcon />
          </Button>
          </Tooltip>
        }
      <Menu
        id="emotion-picker"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'emotion-picker-button',
        }}
        sx={{
          ".MuiList-root":{
            p: 0
          }
        }}
      >
        <EmojiPickerElement onEmojiClick={onEmojiClick} />
      </Menu>
    </div>
  );
}

export default EmojiPicker