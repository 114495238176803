import React, {useState} from 'react'
import sessionStore from '../stores/sessionStore'
import LanguageSwitch from './LanguageSwitch'
import { useTranslation } from "react-i18next"
import Loading from './Loading'

import { Button, Box, TextField, IconButton, Tooltip, Divider } from '@mui/material'
import Infobox from './Infobox'
import { env } from '../api/api'
import { navigate } from "wouter/use-location"
import { Info, Key } from '@mui/icons-material'

const Login: React.FC = () => {
    const { t } = useTranslation()
    const { _login, _podId } = Object.fromEntries(new URLSearchParams(window.location.search))
    const podId = _podId ? _podId : ''

    const [loading, setLoading] = useState(false)
    const [showShrimpLogin, setShowShrimpLogin] = useState<true|false|undefined>(podId ? true : undefined)
    const [showInformation, setShowInformation] = useState(false)
    const [scope, setScope] = useState<string>(podId)
    const [login, setLogin] = useState<string>(_login ? _login : '')
    const [password, setPassword] = useState<string>('')

    const handleKeycloakLogin = (async() =>{
      setLoading(true)
      await sessionStore.login('keycloak')
      setLoading(false)
    })

    const handleMbrLogin = (async() =>{
      setLoading(true)
      await sessionStore.login('mbr')
      setLoading(false)
    })

    const handleSatosaLogin = (async() =>{
      setLoading(true)
      await sessionStore.login('satosa')
      setLoading(false)
    })

    const handleLocalLogin = async() => {
      setLoading(true)
      const r = await sessionStore.login('local', { login, password, podId:scope })
      setLoading(false)
      if (r) setShowShrimpLogin(false)
    }

    if(loading) return <Infobox>
      <Loading info="Waiting for login" />
    </Infobox>

    const buttons:JSX.Element[] = []

    if (env.idps.findIndex((idp:any)=>idp.name==='satosa') > -1) buttons.push(<Button key='satosa' sx={{margin:'10px 0 10px 0px',width:'100%'}} style={{justifyContent:'left'}} variant="outlined" onClick={handleSatosaLogin} disabled={loading} startIcon={<img src="/img/saml.png" />}>
      {t('Login via DFN / University')}
    </Button>)
    if (env.idps.findIndex((idp:any)=>idp.name==='mbr') > -1) buttons.push(<Button key='mbr' sx={{margin:'10px 0 10px 0px',width:'100%'}} style={{justifyContent:'left'}} variant="outlined" onClick={handleMbrLogin} disabled={loading} startIcon={<img src="/img/oidc.png" />}>
      {t('Login via Mein Bildungsraum')}
    </Button>)
    if (env.idps.findIndex((idp:any)=>idp.name==='keycloak') > -1) buttons.push(<Button key='keycloak' sx={{margin:'10px 0 10px 0px',width:'100%'}} style={{justifyContent:'left'}} variant="outlined" onClick={handleKeycloakLogin} disabled={loading} startIcon={<span style={{width:24, height:24, backgroundColor:'#777', border:'1px solid #00c'}} />}>
      {t('Login via Keycloak')}
    </Button>)

    if (env.idps.findIndex((idp:any)=>idp.name==='local') > -1) buttons.push(<Box>
      <Divider style={{marginTop:'1em'}} />
      <small>Login for Schools and external users:</small>
      <Button key='local' sx={{margin:'10px 0 10px 0px',width:'100%'}} style={{justifyContent:'left'}} variant="outlined" onClick={() => setShowShrimpLogin(!showShrimpLogin)} disabled={loading} startIcon={<Key fontSize="inherit" />}>
        {t('Login via SHRIMP Login')}
      </Button>
    </Box>)


    const localForm = <Box style={{backgroundColor:'white'}}>
      <Box style={{fontSize:'85%', lineHeight:1.2, marginBottom:'1.5em'}}>
        {t('Login via username and password is only available if you have received login credentials from your teacher or from the SHRIMP team.')}
      </Box>
      <TextField size='small' sx={{m:'10px 0', width:'100%'}} disabled={Boolean(podId)} value={scope} onChange={(e) => { setScope(e.target.value) }} label={t('Pod Id')} type="text" name="podId" />
      <TextField size='small' sx={{m:'10px 0', width:'100%'}} value={login} onChange={(e) => { setLogin(e.target.value) }} label={t('Login')} type="text" name="login" />
      <TextField size='small' sx={{m:'10px 0 20px 0', width:'100%'}} value={password} onChange={(e) => { setPassword(e.target.value) }} label={t('Password')} type="password" name="password" />
      <Box style={{textAlign:'right'}}>
        <Button onClick={()=> { setShowShrimpLogin(false); setScope(''); setLogin(''); setPassword(''); navigate('/') }}>{t('Cancel')}</Button>
        <Button variant='contained' onClick={()=> handleLocalLogin()}>{t('OK')}</Button>
      </Box>
    </Box>

    return (
      <>
        <div style={{justifySelf: "end", margin: "10px"}}>
          <LanguageSwitch />
        </div>
        <Infobox>
          <Box sx={{display: "grid", gridTemplateColumns: "min-content auto min-content", alignItems: "center", overflow:'hidden'}}>
            <Box  sx={{display: "grid", gridTemplateRows: "min-content auto min-content", alignItems: "start", borderRight:'1px solid #000', padding:'0 8px 0 0', height:'100%', backgroundColor:'#fff', zIndex:100}}>
              <img src="/img/logo512.png" style={{width:72, float:'left'}} alt="SHRIMP Logo" />
              <Box></Box>
              <Box sx={{textAlign:'center'}}>
                <Tooltip title={t('More information on SHRIMP (including contact information and the webpage imprint)')}>
                  <IconButton size="small" onClick={() => setShowInformation(!showShrimpLogin)}>
                    <Info fontSize="inherit" />
                  </IconButton>
                </Tooltip>
{/*                <Tooltip title={t('If you have a login and password issued by SHRIMP you may use this local login option')}>
                  <IconButton size="small" onClick={() => setShowShrimpLogin(!showShrimpLogin)} color={showShrimpLogin ? 'success' : 'default'}>
                    <Key fontSize="inherit" />
                  </IconButton>
    </Tooltip> */ }
              </Box>
            </Box>
            <Box sx={{display: "grid", gridTemplateRows: "min-content auto min-content", alignItems: "start", paddingLeft:1}}>
              <Box sx={{paddingBottom:1}}>{t("You must log in")}</Box>
              <Box>
                { showShrimpLogin ? localForm : <Box sx={{paddingBottom:2}}>{buttons}</Box> }
              </Box>
              <Box sx={{paddingTop:0,textAlign:'right'}}>
              </Box>
            </Box>
            <div></div>
          </Box>
        </Infobox>
      </>
    )
  }

export default Login