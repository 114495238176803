import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"

const ViewMark = ({nodeId, pageNo}: {nodeId: string, pageNo: number}) => {
  const { podStore, uiStore } = useStore()
  const viewMarkSize = 8
  var viewMarkStatus:any = {border:'1px solid #0a0'}
  if (podStore.getView('pdfPage', nodeId, pageNo).dSeen>uiStore.readingTimer) viewMarkStatus = {backgroundColor:'#0a0'}

  return (
    <div style={{position:'absolute', ...viewMarkStatus, right:0, marginTop:6, width:viewMarkSize, height:viewMarkSize}}>&nbsp;</div>
  )
}

export default observer(ViewMark)