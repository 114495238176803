import React, { createRef, useEffect } from 'react'
// components
import { baseUrl } from '../../api/api'
import Thumbnails from '../Pdf/Thumbnails/Thumbnails'
import Viewer from '../Pdf/Viewer'
// mui
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import ErrorBoundaryComponent from '../ErrorBoundaryComponent'
import InteractionDrawer from '../Pdf/InteractionDrawer/InteractionDrawer'
import { Box } from '@mui/material'
import TransformInteractionMenu from '../Elements/TransformInteractionMenu'
import OverlappingInteractionsMenu from '../Elements/OverlappingInteractionsMenu'
import PdfFooter from '../Pdf/PdfFooter'

const Pdf = ({pdfId}: {pdfId:string|undefined}) => {
  const { podStore, sessionStore, uiStore, pdfMetaStore } = useStore()
  const pod = podStore.pod

  useEffect(() => {
    const element = document.getElementById('root')
    if (element) {
      element.addEventListener('wheel', handleWheel, {
        capture:true,
        passive:false,
      })
      element.addEventListener('touchstart', touchstart, {
        capture:true,
        passive:false,
      })
      element.addEventListener('touchmove', touchmove, {
        capture:true,
        passive:false,
      })
      element.addEventListener('touchend', touchend, {
        capture:true,
        passive:false,
      })
    }
    return () => {
      if (element) {
        element.removeEventListener('wheel', handleWheel)
        element.removeEventListener('touchstart', touchstart)
        element.removeEventListener('touchmove', touchmove)
        element.removeEventListener('touchend', touchend)
      }
    }
  })

  const pdfUrl = `${baseUrl}/pdf/${pdfId}`
  const thumbnailUrl = `${baseUrl}/pageView/${pdfId}`
  const pageListRef: any = createRef()
  const thumbListRef: any = createRef()

  var pinchZoomStartDist: number
  var pinchZoomOldValue: number

  const handleWheel = (e:any) =>{
    if (e.ctrlKey) {
      const scale = pdfMetaStore.getScale(pdfUrl)
      if (e.deltaY>0) pdfMetaStore.setScale(pdfUrl, scale-.1); else pdfMetaStore.setScale(pdfUrl, scale+.1)
      e.stopPropagation();
      e.preventDefault()
  } }

  const touchstart = (e:any) => {
    if (e.touches.length === 2) {
      pinchZoomStartDist = Math.hypot(
          e.touches[0].pageX - e.touches[1].pageX,
          e.touches[0].pageY - e.touches[1].pageY
      )
      pinchZoomOldValue = pdfMetaStore.getScale(pdfUrl)
      uiStore.setIsPinchZooming(true)
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  const touchmove = (e:any) => {
    if (e.touches.length === 2) {

      if (!pinchZoomOldValue) {
        pinchZoomOldValue = pdfMetaStore.getScale(pdfUrl)
        pinchZoomStartDist = Math.hypot(
          e.touches[0].pageX - e.touches[1].pageX,
          e.touches[0].pageY - e.touches[1].pageY
        )
      }

      const dist = Math.hypot(
              e.touches[0].pageX - e.touches[1].pageX,
              e.touches[0].pageY - e.touches[1].pageY
      )

      const val:number = Math.floor(10 * pinchZoomOldValue * (dist / pinchZoomStartDist))/10
      pdfMetaStore.setScale(pdfUrl, val)
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  const touchend = (e:TouchEvent) => {
    if (e.changedTouches.length !== 2) {
      uiStore.setIsPinchZooming(false)
    }
  }

  if (!pdfId) return  <div>Could not render the pdf file. PdfId missing.</div>
  if (!pod) return <div>Could not render the pdf file "{pdfId}". Pod missing.</div>

  const podId = pod.podId
  const file = pod.content.pdfFiles[pdfId]
  if (!file) return <div>Could not render the pdf file. No Pdf file found.</div>

  if (pod.nodeIsHidden(pdfId) && file.userId !== sessionStore.session.user.userId) return <div>Could not render the pdf file (1).</div>
  if (!pod.isVisible('pdfFile', pdfId)) return <div>Could not render the pdf file (2).</div>

  return <ErrorBoundaryComponent>
    <Box sx={{display: "grid", gridTemplateRows: "auto min-content"}}>
      <Box sx={{
        backgroundColor: "whitesmoke",
        display: "grid",
        gridTemplateColumns: uiStore.deviceWidth === "tiny" ? "auto" : `auto ${uiStore.getThumbContainerWidth()}px`,
        overflowY: "auto",
      }}>
        <ErrorBoundaryComponent context="Pdf>Viewer">
          <Viewer
            pageListRef={pageListRef}
            pdfId={pdfId}
            pdfUrl={pdfUrl}
            podId={podId}
            storeId={pdfUrl}
            thumbListRef={thumbListRef}
            thumbnailUrl={thumbnailUrl}
          />
        </ErrorBoundaryComponent>
        <Thumbnails
          pageListRef={pageListRef}
          storeId={pdfUrl}
          thumbListRef={thumbListRef}
          thumbnailUrl={thumbnailUrl}
        />
        <InteractionDrawer
          pageListRef={pageListRef}
          storeId={pdfUrl}
        />
        <TransformInteractionMenu />
        <OverlappingInteractionsMenu podId={podId} pdfId={pdfId} />
      </Box>
      <PdfFooter storeId={pdfUrl} />
    </Box>
  </ErrorBoundaryComponent>
}

export default observer(Pdf)
