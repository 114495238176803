import { observer } from "mobx-react-lite"
import { useStore } from "../../../contexts/store"
import { Interaction, InteractionType, iAnnotation, iComment, iEmotion, iLink, iReadingQuestion, iTag, iWeblink } from '../../../../../types/Interaction'
import { Box } from "@mui/material"
import MarginaliaIcon from "./MarginaliaIcon"
import MarginaliaEmoji from "./MarginaliaEmoji"
import MarginaliaNote from "./MarginaliaNote"


const Marginalia = (
  {
    pageNumber,
    pdfId,
    podId,
    interactionsShown
  }: {
    pageNumber: number,
    pdfId: string,
    podId: string,
    interactionsShown: InteractionType[]
  }
) => {
  const { podStore, uiStore } = useStore()
  const pdfUrl = `/pod/${podId}/pdf/${pdfId}`
  // width of page margin
  const pageMargin = uiStore.pageMargin
  const radius = uiStore.marginaliaIconRadius

  // list of interactions from which marginalia should be visible
  const marginaliaList: Array<Interaction> = []

  // add the interactions selected for the page margin
  for(const interactionType of interactionsShown) {
    if(interactionType === "annotation") {
      // load annotations for actual page
      const annotations: iAnnotation[] | null = podStore.getNotes(pdfId, pageNumber)
      if(annotations && annotations.length) annotations.forEach(i => { if (i.anchor.rects[0].p === pageNumber) marginaliaList.push(i) })
    }
    else if(interactionType === "comment") {
      // load comments for actual page and add to list
      const comments: iComment[] | null = podStore.getComments(pdfId, pageNumber)
      if(comments && comments.length) comments.forEach(i => { if (i.anchor.rects[0].p === pageNumber) marginaliaList.push(i) })
    }
  else if(interactionType === "link") {
      // load links for actual page and add to list
      const links: iLink[] | null = podStore.getLinks(pdfId, pageNumber)
      if(links && links.length) links.forEach(i => { if (i.anchor.rects[0].p === pageNumber) marginaliaList.push(i) })
    }
    else if(interactionType === "tagging") {
      // load tags for actual page and add to list
      const tags: iTag[] | null = podStore.getTags(pdfId, pageNumber)
      if(tags && tags.length) tags.forEach(i => { if (i.anchor.rects[0].p === pageNumber) marginaliaList.push(i) })
    }
    else if(interactionType === "weblink") {
      // load weblinks for actual page and add to list
      const weblinks: iWeblink[] | null = podStore.getWeblinks(pdfId, pageNumber)
      if(weblinks && weblinks.length) weblinks.forEach(i => { if (i.anchor.rects[0].p === pageNumber) marginaliaList.push(i) })
    }
    else if(interactionType === "emotion") {
      // load emtions for actual page and add to list
      const emotions: iEmotion[] | null = podStore.getEmotions(pdfId, pageNumber)
      if(emotions && emotions.length) emotions.forEach(i => { if (i.anchor.rects[0].p === pageNumber) marginaliaList.push(i) })
    }
    else if(interactionType === "readingQuestion") {
      // load readingQuestions for actual page and add to list
      const readingQuestions: iReadingQuestion[] | null = podStore.getReadingQuestions(pdfId, pageNumber)
      if(readingQuestions && readingQuestions.length) readingQuestions.forEach(i => { if (i.anchor.rects[0].p === pageNumber) marginaliaList.push(i) })
    }
    else {
      console.warn("unsupported interaction type in Marginalia", interactionType)
    }
  }

  // no marginalia available on this page
  if(marginaliaList.length === 0 || pageMargin === undefined) return null

  // sort order of interactions
  marginaliaList.sort((a: any, b: any) => {
    const aPageNumber = a.anchor.rects[0].p
    const aX = Math.floor(a.anchor.rects[0].x)
    const aY = Math.floor(a.anchor.rects[0].y)

    const bPageNumber = b.anchor.rects[0].p
    const bX = Math.floor(b.anchor.rects[0].x)
    const bY = Math.floor(b.anchor.rects[0].y)

    // compare by page number
    if (aPageNumber > bPageNumber) return 1
    if (aPageNumber < bPageNumber) return -1
    // interaction are on the same page: compare vertical position of the first rectangle
    if(aY > bY) return 1
    if(aY < bY) return -1
    // interactions are on the same height: compare horizontal position of the first rectangle
    if(aX > bX) return 1
    if(aX < bX) return -1
    // interactions share the same position of the first rectangle
    return 0
  })

  // returns appropriate types of marginalia depending on the screen width and interaction type
  const createMarginalia = (item: Interaction) => {
    const deviceWidth = uiStore.deviceWidth
    // show note content on large screens
    if(item.interactionType === "annotation" && (deviceWidth === "medium" || deviceWidth === "large")) {
      const height = 50
      return (
        {
          height: height,
          width: pageMargin,
          item: (
            <MarginaliaNote
              height={height}
              item={item as iAnnotation}
              key={item.interactionId}
              pdfUrl={pdfUrl}
              width={pageMargin}
            />
          )
        }
      )
    }
    // show emotion as icon
    if(item.interactionType === "emotion") return (
      {
        height: radius,
        width: radius,
        item: (
          <MarginaliaEmoji
            radius={radius}
            item={item}
            key={item.interactionId}
            pdfId={pdfId}
            pdfUrl={pdfUrl}
          />
        )
      }
    )
    // show icon for interaction
    else return (
      {
        height: radius,
        width: radius,
        item: (
          <MarginaliaIcon
            radius={radius}
            item={item}
            key={item.interactionId}
            pdfUrl={pdfUrl}
          />
        )
      }
    )
  }

  // create page margin with marginalias
  const Margin = () => {
    // elements which create margin sidebar
    const elements: any = []
    // save marginalia until the next placeholder is added,
    // so that too many interactions can be replaced by an icon.
    const marginaliaMemory: any[] = []
    // status position for gap calculation
    let yPos = 0
    let xPos = 0
    // shifts the marginalia slightly upwards, regardless of their height
    const marginBottom = 10
    // mui iconButton elements create hidden margin on top, if button is too small
    let iconTop = 0
    if ((25 - radius) > 0) {
      iconTop = Math.round((25-radius))
    }
    // for every marginalia item
    // if item y position is larger than the current y status position:
    // fill the gap with an appropriately sized element.
    // otherwise add the element to the margin
    if(pageMargin && marginaliaList.length) {
      marginaliaList.forEach((item: Interaction) => {
        // y position from selected text which refers to marginalia
        const itemY = item.anchor.rects[0].y
        const itemH = item.anchor.rects[0].h
        // get marginalia item and its specs
        const createdMarginalia = createMarginalia(item)
        const marginaliaItem = createdMarginalia?.item
        const marginaliaHeight = createdMarginalia?.height
        const marginaliaWidth = createdMarginalia?.width

        if(marginaliaItem && marginaliaHeight && marginaliaWidth) {
          // if y position of item is already reached, add it to the margin
          if(itemY <= (yPos + marginBottom) ) {
            marginaliaMemory.push(marginaliaItem)
            // if page margin starts with marginalia add marginalia height
            if(marginaliaMemory.length === 1) yPos += marginaliaHeight + iconTop
            // when the items become wider than the margin, they move to the next line.
            // which increases the y status by the height of the item
            // and sets the x status to the width of the item
            xPos += marginaliaWidth
            if(xPos >= pageMargin) {
              yPos += marginaliaHeight + iconTop
              xPos = marginaliaWidth
            }
          }
          // if the y position has not yet been reached,
          // draw marginalia included so far
          // place box with the corresponding missing space in front of the next item
          else {
            // draw list of marginalia
            if(marginaliaMemory.length) {
              elements.push(...marginaliaMemory)
              // clear array
              marginaliaMemory.length = 0
            }

            // calculate gap to next item
            const yDiff = itemY - yPos- marginBottom
            // draw box to fill the gap
            elements.push(
              <Box key={`${item.interactionId}Margin`} sx={{width: "100%", height: `${yDiff}px`}} />
            )
            // add marginalia item
            marginaliaMemory.push(marginaliaItem)

            // set yPos to the sum of the added gap and the element itself
            yPos += yDiff + marginaliaHeight + iconTop
            // set xPos to the width of the added item or width of pageMargin if the element occupies the entire width
            xPos = marginaliaWidth

          }
        }
      })

      // add backlog
      elements.push(...marginaliaMemory)
    }
    return elements
  }


  return (
    <Box sx={{wordBreak: "break-word"}}>
      <Margin />
    </Box>
  )

}

export default observer(Marginalia)