import { makeObservable, action, observable, computed } from "mobx"
import { t } from "i18next"

import opStore from './opStore'
import uiStore from "./uiStore"
import sessionStore from "./sessionStore"
import alertStore, {alert} from "./alertStore"

import api from '../api/api'

import { Pod, PodLoadState, Usergroup } from '../../../types/Pod'
import { PodClass, PodI } from '../classes/Pod'
import { Interaction, Rect, iAnnotation, iComment, iEmotion, iLink, iReadingQuestion, iTag, iWeblink, interactionAnchor } from '../../../types/Interaction'
import { PdfFile, Tag, fullLink } from "../../../types/Content"
import { Op, Op_addViews, viewData } from "../../../types/Ops"
import { Thread } from "../../../types/Message"
import { UserInfo } from "../../../types/User"
import { ConversationItem, ViewableType } from "../../../types/Miscs"
import { activityOps } from "../helper/activityOps"
import dayjs from "dayjs"

export interface PodStoreModel {
  pod: PodClass|null
  podIsLoading: boolean
  activeLinkEditId: string | null
  userPseudonym: string | null
  userInfo: UserInfo | null
  podActivity: Op[]
  viewTrackerStore: {[key:string]: {tSeen:number, dSeen:number, visible: boolean}}

  getAnnotations: (fileId: string, page: number | null) => iAnnotation[] | null
  getNotes: (fileId: string, page: number | null) => iAnnotation[] | null
  getComments: (fileId: string, page: number | null) => iComment[] | null
  getThread: (interactionId: string) => Thread | null
  getConversations: (userId : number) => null | ConversationItem[]
  getLinks: (fileId: string, page: number | null) => iLink[] | null
  getInteraction: (fileId: string, interactionId: string) => iAnnotation | iComment | iTag | iLink |iEmotion | iWeblink | iReadingQuestion | null
  getLink: (fileId: string | undefined | null, interactionId: string | undefined | null) => fullLink | null
  getTags: (fileId: string, page: number | null) => iTag[] | null
  getWeblinks: (fileId: string, page: number | null) => iWeblink[] | null
  getEmotions: (fileId: string, page: number | null) => iEmotion[] | null
  getReadingQuestions: (fileId: string, page: number | null) => iReadingQuestion[] | null
  getTagProp: (tagId: string) => Tag | null
  getTagPool: () => Tag[]
  getUsergroupByRole: (role: 'Admin'|'Pod'|'Private') => Usergroup

  setActiveLinkEditId: (linkEditId: string | null) => void
  loadPod: (podId: string) => Promise<boolean>
  unsetPod: () => void
  resetPod: (podId: string) => Promise<boolean>

  getLinkLabel: (linkId: string, linkLabel: string | null) => string
  setLinkLabel: (linkId: string, text: string) => void
  deleteLinkLabel: (linkId: string) => void
  getLinkFile: (linkId: string, fileId: string | null) => string
  setLinkFile: (linkId: string, fileId: string) => void
  deleteLinkFile: (linkId: string) => void
  getInteractionEditAnchor: (interactionEditId: string) => interactionAnchor | null
  setInteractionEditAnchor: (interactionEditId: string, anchor: interactionAnchor) => void
  deleteInteractionEditAnchor: (interactionEditId: string) => void
  getInteractionsByCoordinates: (pdfId: string, x: number, y: number, page: number, currentScale: number, pageElement: DOMRect) => {interaction: Interaction, menuAnchor: any}[] | null
  getFilename: (nodeId: string) => string | null
  setPodActivity: (ops: Op[]) => void
  getPodActivity: (podId: string) => Op[] | null
  addToPodActivity: (podId: string, op: Op) => void
  getUserInfo: (userId: number | undefined | null, podId: string | undefined | null) => UserInfo | null

  setOutOfSync: (status:boolean) => void

  setView: (visible: boolean, type: ViewableType, id: string, sub?: number|null|undefined) => void
  getView: (type: ViewableType, id: string, sub?: number|null|undefined) => { tSeen:number, dSeen:number }
  refreshViews: () => void,
  getLastUnseenMessage: (thread: Thread) => string | null
  getNodeId: (interactionId: string | undefined) => null | string
  getPdfViewProgress: (nodeId: string) => number | null
  getFolderFiles: (folderId: string) => PdfFile[]
  getCustomAnnotationColors: () => string[]
}

type PodCondition = {
  status: PodLoadState,
  info: string
}

class podStore {
  podIsLoading: boolean = false
  activePodId: string | null = null
  activePdfId: string | null = null
  activeLinkEditId: string | null = null
  getOpsintervalId: number | null = null
  linkLabel: {[id: string]: string} = {}
  linkFile: {[id: string]: string} = {}
  interactionEditAnchor: {[interactionEditId: string]: interactionAnchor} = {}
  urlParams: {[key:string]: string} = {}

  viewTrackerStore: {[key:string]: {tSeen:number, dSeen:number, visible: boolean}} = {}

  // the currently loaded, active pod.
  pod: PodClass | null = null
  podCondition: {[podId: string]: PodCondition} = {}
  podActivity: Op[] = []

  constructor() {
    makeObservable(this, {
      activePodId: observable,
      activePdfId: observable,
      activeLinkEditId: observable,
      pod: observable,
      podCondition: observable,
      setPod: action,
      setActiveLinkEditId: action,
      resetPod: action,
      unsetPod: action,
      setPodStatus: action,
      getAnnotations: observable,
      getNotes: observable,
      getComments: observable,
      getThread: observable,
      getConversations: observable,
      getLinks: observable,
      getTags: observable,
      getWeblinks: observable,
      getEmotions: observable,
      getReadingQuestions: observable,
      getInteraction: observable,
      getTagProp: observable,
      getTagPool: observable,
      linkLabel: observable,
      getLinkLabel: observable,
      setLinkLabel: action,
      deleteLinkLabel: action,
      linkFile: observable,
      getLinkFile: observable,
      setLinkFile: action,
      deleteLinkFile: action,
      interactionEditAnchor: observable,
      getInteractionEditAnchor: observable,
      setInteractionEditAnchor: action,
      deleteInteractionEditAnchor: action,
      getInteractionsByCoordinates: action,
      userPseudonym: computed,
      userInfo: computed,
      podActivity: observable,
      getPodActivity: observable,
      setPodActivity: action,
      addToPodActivity: action,
      getFilename: action,
      setOutOfSync: action,

      getView: observable,
      viewTrackerStore: observable,
      refreshViews: action,
      setView: action,
      getLastUnseenMessage: action,
      getNodeId: action,
      getPdfViewProgress: observable,
      getFolderFiles: observable,
      getUserInfo: observable,
      getCustomAnnotationColors: action
    })
  }

  setView(visible: boolean, type: ViewableType, id: string, sub: number|null|undefined = null) {
    const signature = `${type} ${id} ${sub}`
    const now = dayjs().unix()
    if (visible) {
      if (uiStore.showVerboseLogging.view && !this.viewTrackerStore[signature]?.visible) console.log(`View: ${signature} appeared at ${now} (with ${this.viewTrackerStore[signature]?.dSeen || 0} s of legacy views)`)
      if (typeof this.viewTrackerStore[signature] === 'undefined') this.viewTrackerStore[signature] = {
        tSeen:now,
        dSeen:0,
        visible:true
      }
      else  {
        this.viewTrackerStore[signature].dSeen += (now-this.viewTrackerStore[signature].tSeen);
        this.viewTrackerStore[signature].tSeen = now;
        this.viewTrackerStore[signature].visible = true
      }
    }
    else if ((typeof this.viewTrackerStore[signature] !== 'undefined') && (this.viewTrackerStore[signature].visible)) {
      const duration = this.viewTrackerStore[signature].dSeen + (now - this.viewTrackerStore[signature].tSeen)
      this.viewTrackerStore[signature] = { tSeen: now, dSeen: duration, visible: false }
      if(uiStore.showVerboseLogging.view) console.log(`View: ${signature} disappeared at ${now} (with ${this.viewTrackerStore[signature].dSeen} s of total viewtime`)
    }
  }

  refreshViews(force:boolean = false) {
    const signatures = Object.keys(this.viewTrackerStore)
    const now = dayjs().unix()
    const chunksize = force ? 100 : 25
    // console.log(`${force ? 'FORCED' : 'unforced'} wipe of views table`)

    if (!this.pod) return
    if (!sessionStore.session) return

    signatures.sort((a, b) => this.viewTrackerStore[b].tSeen - this.viewTrackerStore[a].tSeen)
    const views:viewData[] = []

    for(var i=0; i<Math.min(chunksize, signatures.length); i++) {
      const sig = signatures[i].split(' ')
      const type:ViewableType = sig[0] as ViewableType
      const id:string = sig[1]
      const sub:number= Number(sig[2])

      if (!this.viewTrackerStore[signatures[i]].visible) {
        if (this.viewTrackerStore[signatures[i]].dSeen !== 0) {
          views.push({
            type,
            id,
            sub: Number(sub) || 0,
            tSeen: this.viewTrackerStore[signatures[i]].tSeen,
            dSeen: this.viewTrackerStore[signatures[i]].dSeen,
          })
          delete this.viewTrackerStore[signatures[i]]
        }
      }
      else if ((this.viewTrackerStore[signatures[i]].dSeen > 300) || (force)) {
        views.push({
          type,
          id,
          sub: Number(sub) || 0,
          tSeen: this.viewTrackerStore[signatures[i]].tSeen,
          dSeen: this.viewTrackerStore[signatures[i]].dSeen + (now - this.viewTrackerStore[signatures[i]].tSeen),
        })
        this.viewTrackerStore[signatures[i]].dSeen = 0
        this.viewTrackerStore[signatures[i]].tSeen = now
      } else {
        // console.log('rebook', signatures[i])
        this.viewTrackerStore[signatures[i]].dSeen += now - this.viewTrackerStore[signatures[i]].tSeen
        this.viewTrackerStore[signatures[i]].tSeen = now
      }
    }

    // console.log(`Identified ${views.length} reapable views:`, views.length ? views : [])

    if (views.length) {
      const op:Op_addViews = {
        op: 'addViews',
        podId: this.pod.podId,
        data: {
          usergroupId: this.getUsergroupByRole('Private').usergroupId,
          userId: sessionStore.session.user.userId,
          userName: this.userPseudonym || '',
          views
        },
      }
      opStore.doOp(op)
    }
  }

  getView(type:ViewableType, id: string, sub:number|null|undefined=null) {
    const signature = `${type} ${id} ${sub}`
    var tSeen:number = this.viewTrackerStore[signature]?.tSeen || 0
    var dSeen:number = this.viewTrackerStore[signature]?.dSeen || 0

    switch(type) {
      case 'message':
        const msg = this.pod?.getMessage(id)
        if (msg) { tSeen = Math.max(tSeen, msg.tSeen || 0); dSeen += msg.dSeen || 0 }
        break
      case 'comment':
      case 'emotion':
      case 'link':
      case 'readingQuestion':
      case 'tagging':
      case 'weblink':
        const int = this.pod?.getInteraction(id)
        if (int) { tSeen = Math.max(tSeen, int.tSeen || 0); dSeen += int.dSeen || 0 }
        break
      case 'pdfPage':
        if (sub) {
          const page = this.pod?.content.pdfFiles[id]?.pages[sub]
          if (page) { tSeen = Math.max(tSeen, page.tSeen || 0); dSeen += page.dSeen || 0 }
        }
        break
      default:
        console.warn(`Cannot (yet) account for ${type}`)
      }
    return {
      tSeen,
      dSeen
    }
  }

  getPdfViewProgress(nodeId: string) {
    const pdf = this.pod?.content.pdfFiles[nodeId]
    let viewProgress = null
    if(pdf && pdf.nofPages) {
      const nofPages = pdf.nofPages
      let viewedPages = 0
      for(let pageNumber = 1; pageNumber <= nofPages; pageNumber++) {
        const view = this.getView("pdfPage", nodeId, pageNumber)
        if(view.dSeen > uiStore.readingTimer) viewedPages++
      }
      viewProgress = Math.round((viewedPages / nofPages) * 100)
    }
    return viewProgress
  }

  getFolderFiles(folderId: string) {
    const pdfFiles = this.pod?.content.pdfFiles
    const folderFiles = []
    if(pdfFiles) {
      for(const pdfId in pdfFiles) {
        const pdf = pdfFiles[pdfId]
        if(pdf.folderId === folderId && !pdf.hidden) folderFiles.push(pdf)
      }
    }
    return folderFiles
  }

  getLastUnseenMessage(thread: Thread) {
    let messageId = null
    let messageFound = false
    for (const message of thread.messages) {
      const signature = `message ${message.messageId} ${null}`
      const dSeen:number = (this.viewTrackerStore[signature]?.dSeen + (message.dSeen || 0)) || (message.dSeen || 0)
      // detect unread message
      if(dSeen < 2 && message.userId !== sessionStore.session.user.userId && !messageFound) {
        messageId = message.messageId
        messageFound = true
      }
      // if there is another message from the user after an unread message,
      // search for the next unread message from that point onwards
      if(message.userId === sessionStore.session.user.userId && messageFound) {
        messageId = null
        messageFound = false
      }
    }
    return messageId
  }

  get userPseudonym() {
    const userInfo = this.userInfo
    if (userInfo) return userInfo.userName
    return null
  }

  get userInfo() {
    if ((this.pod) && (sessionStore.session.user.userId)) return this.pod.userInfos[sessionStore.session.user.userId]
    return null
  }

  async resetPod(podId: string) {
    delete this.podCondition[podId]
    if (podId === this.pod?.podId) this.unsetPod()
    await api.loadPod(podId, true)
    return true
  }

  /** trigger loading pod with podId */
  async loadPod(podId: string, force: boolean = false) {
    const t0 = Date.now()

    let pod: PodClass

    // Do not load pods that are already loaded
    if ((!force) && (this.pod?.podId === podId) && (this.pod.status === 'loaded')) {
      return true
    }

    if (this.pod?.status === 'loading') {
      console.warn(`Warning: loadPod() was called while a pod was loading. Cannot load pod ${podId} while ${this.pod.podId} is loading.`)
      return false
    }

    // console.log(`Loading pod ${podId}`, this.pod)

    if (true) {
      pod = new PodClass(null, true)
      pod.podId = podId
      pod.status = 'loading'
      this.setPod(pod)
    }

    // Initialize with empty 'init' version of the pod (or get the full Pod if the serviceWorker has it)
    const loadedPod = await api.loadPod(podId)

    if (loadedPod) {

      if (loadedPod.status === 'unknown') {
        pod.setStatus('unknown')
        this.setPod(pod)
        const pendingOps = await api.getPendingOps(podId)
        if (pendingOps) {
          if (uiStore.showVerboseLogging.loadPod) console.log(`Applying ${pendingOps.length} pending OPs`)
          pendingOps.forEach((op:Op) => {
            opStore.execute(pod, op)
          })
        }
        return true
      }

      if (loadedPod.status === 'deleted') {
        pod.setStatus('deleted')
        this.setPod(pod)
        const sessionPods = sessionStore.session.pods.filter((pod: Pod) => pod.podId !== podId)
        sessionStore.setPods(sessionPods)
        return true
      }

      if (loadedPod.status === 'broken') {
        pod.status = 'broken'
        this.setPod(pod)
        return true
      }

      pod = loadedPod

      if ((pod.lastSyncOid >= pod.initMaxCoid) && (pod.status === 'loaded')) {
        // query wurde vom SW mit einem vollständigen Pod beantwortet. Apply pending Ops
        if (uiStore.showVerboseLogging.loadPod) console.log(`Finished loading in ${Date.now()-t0}ms.`)

        const pendingOps = await api.getPendingOps(podId)
        if (uiStore.showVerboseLogging.loadPod) console.log(`Applying ${pendingOps?.length} pending OPs`)
        if (pendingOps) pendingOps.forEach((op:Op) => {
          opStore.execute(pod, op)
        })

        this.setPod(pod)
        return true
      }
    }
    else {
      console.error(`Could not load pod: nothing returned`)
      alertStore.push(alert(('Could not load pod: Are you connected to the internet?'), 'error'))
      this.setPodStatus(pod, 'broken' as PodLoadState)
      return false
    }

    if (pod.status !== 'initialized') {
      console.warn(`Error condition: This should not happen! Pod status === ${pod.status}`)
      this.setPodStatus(pod, 'broken' as PodLoadState, `Error condition: This should not happen! Pod status === ${pod.status}`)
      //this.unsetPod()
      return false
    }

    if (pod.lastSyncOid as number === pod.initMaxCoid as number) {
      if (pod.initMaxCoid as Number === 0) {
        console.log('Pod appears to be empty (and is thus loaded)')
        pod.lastSyncOid = pod.initMaxCoid = pod.loadtimeMaxOid
        this.setPodStatus(pod, 'loaded' as PodLoadState)
        this.setPod(pod)
        return true
      }
      else {
        console.warn(`Error condition: This should not happen! (lastSyncOid === initMaxCoid)`)
        this.setPodStatus(pod, 'broken' as PodLoadState, `Error condition: This should not happen! (lastSyncOid === initMaxCoid)`)
        //this.unsetPod()
        return false
      }
    }

    if (uiStore.showVerboseLogging.loadPod) console.log(`Pod was initialized but is incomplete: ${pod.lastSyncOid} !== ${pod.initMaxCoid} ||  ${pod.status} !== 'loaded' --> continue with chunked loading` )

    // Pod ist nur ein initPod: perform load
    this.setPodStatus(pod, 'loading' as PodLoadState)
    pod.setLoadStatus(0)
    let completedOpsCounter = 0

    this.setPod(pod)

    try {

      do {

        if (uiStore.showVerboseLogging.loadPod) console.log('loading chunk ' + pod.lastSyncOid)
        const chunk = await api.loadPodChunk(podId, pod.lastSyncOid, pod.initMaxCoid)

        if (chunk) {
          const { ops, totalOps } = chunk

          ops.forEach((op:any) => {
            opStore.execute(pod, op)
            pod.lastSyncOid = op.data.coid
            if (op.oid === pod.initMaxCoid) {
              this.setPodStatus(pod, 'loaded' as PodLoadState)
            }
          })

          completedOpsCounter += ops.length
          pod.setLoadStatus(totalOps ? Math.floor(100 * completedOpsCounter / totalOps) : 0)

          if (this.pod?.podId === pod.podId) this.setPod(pod)
        }

      } while(pod.status !== 'loaded' as PodLoadState)

      // Get unsynced OPs from serviceWorker and apply
      // (If they were created based on an older version of the pod, they will still get applied
      // based on this new version in the backend, so we should mimick this behavior here)
      const pendingOps = await api.getPendingOps(podId)
      if (pendingOps) {
        if (uiStore.showVerboseLogging.loadPod) console.log(`Applying ${pendingOps.length} pending OPs`)
        pendingOps.forEach((op:Op) => {
          // pod.applyOp(op) //
          opStore.execute(pod, op)
        })
      }
    } catch(e) {
      console.error(`Error condition in fetch: `, e)
      this.setPodStatus(pod, 'broken' as PodLoadState)
      this.unsetPod()
      return false
    }

    api.getPodActivity(podId, pod.loadtimeMaxOid+1, true)

    if (uiStore.showVerboseLogging.loadPod) console.log(`Finished loading in ${Date.now()-t0}ms`)
    return true
  }

  setPodStatus(pod:PodI, status: PodLoadState, info: string = '') {
    if ((pod.status === 'loading') && (status === 'loaded')) pod.setLastSyncOid(pod.loadtimeMaxOid)
    pod.setStatus(status)
    this.podCondition[pod.podId] = {
      status: status,
      info,
    }
  }

  unsetPod() {
    this.refreshViews(true)
    this.pod = null
  }

  setPod(pod: PodI, info: string = '') {
    if (pod && pod.podId) {
      this.pod = pod
      if (pod.status) this.podCondition[pod.podId] = {
        status: pod.status,
        info,
      }
    }
  }

  getUsergroupByRole(role:'Admin'|'Pod'|'Private') {
    if (this.pod) return this.pod.getUsergroupByRole(role)
    throw(new Error('eee'))
  }

  getAnnotations(fileId: string, page: number | null = null) {
    const fileAnnotations = this.pod?.getAnnotations(fileId)
    if(!fileAnnotations) {
      console.error(`getAnnotations() could not find file ${fileId}`)
      return null
    }

    if (fileAnnotations && fileAnnotations.length) {
      if (page === null) return fileAnnotations
      return fileAnnotations.filter((annotation: iAnnotation) => {
        return annotation.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getNotes(fileId: string, page: number | null = null) {
    const fileAnnotations = this.pod?.getAnnotations(fileId)
    if(!fileAnnotations) {
      console.error(`getAnnotations() could not find file ${fileId}`)
      return null
    }

    if (fileAnnotations && fileAnnotations.length) {
      if (page === null) {
        return fileAnnotations.filter((annotation: iAnnotation) => {
          if(annotation.label) return true
          return false
        })
      }

      return fileAnnotations.filter((annotation: iAnnotation) => {
        return annotation.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page && annotation.label) return true; else return false
        }).length
      })
    }
    return []
  }

  getComments(fileId: string, page: number | null = null) {
    const fileComments = this.pod?.getComments(fileId)
    if(!fileComments) {
      console.error(`getComments() could not find file ${fileId}`)
      return null
    }

    if (fileComments && fileComments.length) {
      if (page === null) return fileComments
      return fileComments.filter((comment: iComment) => {
        return comment.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getThread(interactionId: string) {
    const threads = this.pod?.content?.threads
    if(threads) {
      for(let id in threads) {
        const thread = threads[id]
        if(thread.interactionId === interactionId) return thread
      }
    }
    return null
  }

  getConversations(userId: number) {
    if(!this.pod || !userId) return null
    // load threads in which the user is involved
    const threads = this.pod.content?.threads
    const conversations: ConversationItem[] = []
    const conversationIds: string[] = []
    if(threads) {
      for(let id in threads) {
        const thread = threads[id]
        const baseInteraction = this.pod.getInteractionFromThreadId(thread.threadId)
        // if there is no baseInteraction thread was probably deleted
        if(baseInteraction && baseInteraction.interactionType === "comment") {
          if(baseInteraction.userId === userId) {
            const conversationItem = this.createConversationItem(thread, baseInteraction)
            if(conversationItem && !conversationIds.includes(conversationItem.interactionId)) {
              conversations.push(conversationItem)
              conversationIds.push(conversationItem.interactionId)
            }
          }
          else {
            for(let message of thread.messages) {
              if(message.userId === userId) {
                const conversationItem = this.createConversationItem(thread, baseInteraction)
                if(conversationItem && !conversationIds.includes(conversationItem.interactionId)) {
                  conversations.push(conversationItem)
                  conversationIds.push(conversationItem.interactionId)
                }
                break
              }
            }
          }
        }
      }
    }
    return conversations
  }

  createConversationItem(thread: Thread, baseInteraction: Interaction) {
    if(!this.pod) return null
    // create item for list of chat conversations
    const threadId = thread.threadId
    const messages = thread.messages
    const interactionId = thread.interactionId
    const userId = baseInteraction.userId
    const userName = baseInteraction.userName ? baseInteraction.userName : ""
    const label = baseInteraction.label
    const nodeId = baseInteraction.anchor.nodeId
    let tLastMessage = null
    const replies = messages.length
    const involvedUsers: number[] = []
    // get involved users
    if(messages.length) {
      // go from the last to the first message
      for (let index = (messages.length-1); index >= 0; index--) {
        const message = messages[index]
        if(!involvedUsers.includes(message.userId)) {
          involvedUsers.unshift(message.userId)
        }
        // take tCreate from last message
        if(tLastMessage === null) tLastMessage = message.tCreated
      }
      // consider user from base interaction
      if(!involvedUsers.includes(baseInteraction.userId)) {
        involvedUsers.unshift(baseInteraction.userId)
      }
    } else {
      return null
    }
    // check if the thread has unread messages
    let hasUnreadMessages = false
    if(threadId) {
      const thread = this.pod.content.threads[threadId]
      const lastMessageViewed = this.getLastUnseenMessage(thread)
      if(lastMessageViewed) {
        const dSeen = this.getView("message", lastMessageViewed, null).dSeen
        if(dSeen === 0) hasUnreadMessages = true
      }
    }
    // build conversation item
    return ({
      involvedUsers: involvedUsers,
      interactionId: interactionId,
      label: label,
      nodeId: nodeId,
      replies: replies,
      threadId: threadId,
      tLastMessage: tLastMessage ? tLastMessage : baseInteraction.tCreated,
      userId: userId,
      userName: userName,
      hasUnreadMessages: hasUnreadMessages
    })
  }

  getInteraction(fileId: string, interactionId: string) {
    const content = this.pod?.content?.pdfFiles[fileId]
    if(content) {
      const annotation = content.annotations[interactionId]
      if(annotation) return annotation as iAnnotation
      const comment = content.comments[interactionId]
      if(comment) return comment as iComment
      const link = content.links[interactionId]
      if(link) return link as iLink
      const tag = content.taggings[interactionId]
      if(tag) return tag as iTag
      const weblink = content.weblinks[interactionId]
      if(weblink) return weblink as iWeblink
      const emotion = content.emotions[interactionId]
      if(emotion) return emotion as iEmotion
      const readingQuestion = content.readingQuestions[interactionId]
      if(readingQuestion) return readingQuestion as iReadingQuestion
      console.warn("getInteraction: could not find any interaction matching the id", interactionId)
    }
    return null
  }

  getNodeId(interactionId: string | undefined) {
    const pdfFiles = this.pod?.content.pdfFiles
    if(pdfFiles && interactionId) {
      for(const fileId in pdfFiles) {
        const content = pdfFiles[fileId]
        if(content.annotations[interactionId]) return fileId
        if(content.comments[interactionId]) return fileId
        if(content.links[interactionId]) return fileId
        if(content.taggings[interactionId]) return fileId
        if(content.weblinks[interactionId]) return fileId
        if(content.emotions[interactionId]) return fileId
      }
    }
    return null
  }

  getLinks(fileId: string, page: number | null = null) {
    const fileLinks = this.pod?.getLinks(fileId)
    if(!fileLinks) {
      console.error(`getLinks() could not find file ${fileId}`)
      return null
    }

    if (fileLinks && fileLinks.length) {
      if (page === null) return fileLinks
      return fileLinks.filter((link: iLink) => {
        return link.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getLink(fileId: string | undefined | null, interactionId: string | undefined | null) {
    if(fileId && interactionId ) {
      const file = this.pod?.content?.pdfFiles[fileId]
      if(file) {
        const interactionLink = file.links[interactionId]
        // get link object with source and destination
        if(interactionLink) {
          const link = this.pod?.content.links[interactionLink.linkId]
          // console.log(`Found link ${interactionLink.linkId}`, JSON.stringify(link))
          if(link) return {
            ...link,
            src: this.pod?.getInteraction(link?.src),
            dst: this.pod?.getInteraction(link?.dst),
          } as fullLink
        }
      }
    }
    // console.warn(`Could not find link-interaction ${interactionId} in file ${fileId}`)
    return null
  }

  getWeblinks(fileId: string, page: number | null = null) {
    const fileWeblinks = this.pod?.getWeblinks(fileId)
    if(!fileWeblinks) {
      console.error(`getComments() could not find file ${fileId}`)
      return null
    }

    if (fileWeblinks && fileWeblinks.length) {
      if (page === null) return fileWeblinks
      return fileWeblinks.filter((link: iWeblink) => {
        return link.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getEmotions(fileId: string, page: number | null = null) {
    const fileEmotions = this.pod?.getEmotions(fileId)
    if(!fileEmotions) {
      console.error(`getEmotions() could not find file ${fileId}`)
      return null
    }

    if (fileEmotions && fileEmotions.length) {
      if (page === null) return fileEmotions
      return fileEmotions.filter((emotion: iEmotion) => {
        return emotion.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getReadingQuestions(fileId: string, page: number | null = null) {
    const fileReadingQuestions = this.pod?.getReadingQuestions(fileId)
    if(!fileReadingQuestions) {
      console.error(`getReadingQuestions() could not find file ${fileId}`)
      return null
    }

    if (fileReadingQuestions && fileReadingQuestions.length) {
      if (page === null) return fileReadingQuestions
      return fileReadingQuestions.filter((readingQuestion: iReadingQuestion) => {
        return readingQuestion.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getTags(fileId: string, page: number | null = null) {
    const fileTags = this.pod?.getTags(fileId)
    if(!fileTags) {
      console.error(`getTags() could not find file ${fileId}`)
      return null
    }

    if (fileTags && fileTags.length) {
      if (page === null) return fileTags
      return fileTags.filter((interaction: Interaction) => {
        return interaction.anchor.rects.filter((rect: Rect) => {
          if (rect.p === page) return true; else return false
        }).length
      })
    }
    return []
  }

  getTagProp(tagId: string) {
    const tagProp = this.pod?.content.tags[tagId]
    if(tagProp) return tagProp
    return null
  }

  getTagPool() {
    const tags = this.pod?.content.tags
    const tagPool = []
    if(tags) {
      for(let id in tags) {
        const tag = tags[id]
        if(tag.name) tagPool.push(tag)
      }
    }
    return tagPool
  }

  getInteractionsByCoordinates(pdfId: string, x: number, y: number, page: number, currentScale: number, pageElement: DOMRect) {
    const list: {interaction: Interaction, menuAnchor: any}[] = []

    // create a list of interactions that have a rectangle inside the click position
    const annotations: iAnnotation[] | null = this.getAnnotations(pdfId, page)
    if(annotations) {
      for(const annotation of annotations) {
        for(const rect of annotation.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page ) {
            list.push({"interaction": annotation, menuAnchor: this.calculateMenuAnchor(annotation, currentScale, pageElement)})
          }
        }
      }
    }
    const comments: iComment[] | null = this.getComments(pdfId, page)
    if(comments) {
      for(const comment of comments) {
        for(const rect of comment.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({"interaction": comment, menuAnchor: this.calculateMenuAnchor(comment, currentScale, pageElement)})
          }
        }
      }
    }
    const links: iLink[] | null = this.getLinks(pdfId, page)
    if(links) {
      for(const link of links) {
        for(const rect of link.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({"interaction": link, menuAnchor: this.calculateMenuAnchor(link, currentScale, pageElement)})
          }
        }
      }
    }
    const tags: iTag[] | null = this.getTags(pdfId, page)
    if(tags) {
      for(const tagging of tags) {
        for(const rect of tagging.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({"interaction": tagging, menuAnchor: this.calculateMenuAnchor(tagging, currentScale, pageElement)})
          }
        }
      }
    }
    const weblinks: iWeblink[] | null = this.getWeblinks(pdfId, page)
    if(weblinks) {
      for(const weblink of weblinks) {
        for(const rect of weblink.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({"interaction": weblink, menuAnchor: this.calculateMenuAnchor(weblink, currentScale, pageElement)})
          }
        }
      }
    }
    const emtions: iEmotion[] | null = this.getEmotions(pdfId, page)
    if(emtions) {
      for(const emotion of emtions) {
        for(const rect of emotion.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({"interaction": emotion, menuAnchor: this.calculateMenuAnchor(emotion, currentScale, pageElement)})
          }
        }
      }
    }
    const readingQuestions: iReadingQuestion[] | null = this.getReadingQuestions(pdfId, page)
    if(readingQuestions) {
      for(const readingQuestion of readingQuestions) {
        for(const rect of readingQuestion.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === page  ) {
            list.push({"interaction": readingQuestion, menuAnchor: this.calculateMenuAnchor(readingQuestion, currentScale, pageElement)})
          }
        }
      }
    }

    // if one or more interactions have a rectangle at the click position, return them
    if(list.length > 0) return list
    // there is no interaction at the click position
    return null
  }

  // calculate position for menu on selected interaction rects
  calculateMenuAnchor(interaction: Interaction, currentScale: number, pageElement: DOMRect) {
    // reverse engineer boundingClientRect, create anchor for menu position
    const getBoundingClientRect = () => {
      const rect = interaction.anchor.rects[interaction.anchor.rects.length-1]
      return {
        height: rect.h * currentScale,
        left: (rect.x * currentScale) + pageElement.x,
        top: (rect.y * currentScale) + pageElement.y,
        width: rect.w * currentScale,
        x: (rect.x * currentScale) + pageElement.x,
        y: (rect.y * currentScale) + pageElement.y
      }
    }
    const anchor = { getBoundingClientRect , nodeType: 1 }
    return anchor
  }


  setActiveLinkEditId(linkEditId: string | null) {
    this.activeLinkEditId = linkEditId
  }

  getLinkLabel(linkId: string, linkLabel: string | null) {
    // if label does not exist yet, initialize it
    if(this.linkLabel[linkId] === undefined) {
      const label = linkLabel ? linkLabel : ""
      this.linkLabel[linkId] = label
      return label
    }
    return this.linkLabel[linkId]
  }

  setLinkLabel(linkId: string, text: string) {
    this.linkLabel[linkId] = text
  }

  deleteLinkLabel(linkId: string) {
    if(this.linkLabel[linkId] || this.linkLabel[linkId] === "") delete this.linkLabel[linkId]
  }

  getLinkFile(linkId: string, fileId: string | null) {
    // if label does not exist yet, initialize it
    if(this.linkFile[linkId] === undefined && fileId) {
      this.linkFile[linkId] = fileId
      return fileId
    }
    return this.linkFile[linkId]
  }

  setLinkFile(linkId: string, fileId: string) {
    this.linkFile[linkId] = fileId
  }

  deleteLinkFile(linkId: string) {
    if(this.linkFile[linkId]) delete this.linkFile[linkId]
  }

  getInteractionEditAnchor(interactionEditId: string) {
    const overlay = this.interactionEditAnchor[interactionEditId]
    if(overlay) return overlay
    return null
  }

  setInteractionEditAnchor(interactionEditId: string, anchor: interactionAnchor) {
    this.interactionEditAnchor[interactionEditId] = anchor
  }

  deleteInteractionEditAnchor(interactionEditId: string) {
    if(this.interactionEditAnchor[interactionEditId]) delete this.interactionEditAnchor[interactionEditId]
  }

  getFilename(nodeId: string) {
    const pdfFiles = this.pod?.getPdfFiles()
    if(pdfFiles) {
      for(const file of pdfFiles) {
        if(file.nodeId === nodeId) return file.name
      }
    }
    console.warn(`getFilename: no filename with id ${nodeId} found`)
    return null
  }

  getPodActivity(podId:string) {
    if (this.podActivity.length && this.podActivity[0].podId === podId) return this.podActivity
    return null
  }

  setPodActivity(ops: Op[]) {
    this.podActivity = ops
  }

  addToPodActivity(podId:string, op: Op) {
    if ((this.podActivity.length === 0) || this.podActivity[0].podId !== op.podId) return

    // only backend-saved ops can be part of the podActivity log
    if (!op.oid) return

    // only certain ops make it to the activity log
    if (activityOps.indexOf(op.op) === -1) return

    // only new ops will be added to the activity log
    if (this.podActivity.findIndex((o) => o.oid === op.oid) === -1) {
      this.podActivity.unshift(op)
      this.podActivity.sort((a, b) => ((b.oid || 0) - (a.oid || 0)))
      this.podActivity.length = Math.min(this.podActivity.length, 100);
    }
  }

  getUserInfo(userId: number | undefined | null, podId: string | undefined | null) {
    var pod

    // if possible, satisfy from the loaded pod
    if (userId && podId && this.pod && (this.pod.podId === podId) && this.pod.userInfos && this.pod.userInfos[userId]) return this.pod.userInfos[userId]

    // if the loaded pod could not satisfy the request, fall through to try the pod representation in the session
    if (userId && podId && (pod=sessionStore.session.pods.find((p:Pod) => p.podId===podId)) && pod.userInfos && pod.userInfos[userId]) return pod.userInfos[userId]

    // if neither worked, replace a mostly empty representation (if the user requested is the current user, use the idpProvidedUserName as fallback name, if not NULL)
    if (userId) return {
      userId,
      userName: (sessionStore.session.user.userId === userId) ? (sessionStore.session.user.idpProvidedUserName || t('unknown')) : t('unknown'),
      color: "grey"
    }

    return null
  }

  setOutOfSync(status:boolean) {
    if (this.pod) this.pod.outOfSync = status
  }

  getCustomAnnotationColors() {
    const customColors: string[] = []
    const preselectedColors = uiStore.annotationColors
    const pdfFiles = this.pod?.content.pdfFiles
    if(pdfFiles) {
      for(const fileId in pdfFiles) {
        const file = pdfFiles[fileId]
        if(file) {
          const annotations = file.annotations
          for(const id in annotations) {
            const annotation = annotations[id]
            const color = annotation.style.color
            if(color) {
              if(!preselectedColors.includes(color) && !customColors.includes(color)) {
                customColors.push(color)
              }
            }
          }
        }
      }
    }
    return customColors
  }
}


const exportPodStore = new podStore()
export default exportPodStore
