import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { navigate } from "wouter/use-location"
import { useStore } from '../contexts/store'
import { PodClass } from '../classes/Pod'

import { Button, Alert, AlertTitle } from '@mui/material'

import Loading from './Loading'
import LoadingPod from './Pod/LoadingPod'
import Infobox from './Infobox'
import EditUserInfo from './Pod/EditUserInfo'

import { useTranslation } from 'react-i18next'
import { Op_addUserToPod } from '../../../types/Ops'
import { UserInfo } from '../../../types/User'

interface PodProps {
  podId: string
}

const PodProvider = (props: React.PropsWithChildren<PodProps>)  => {
  const { podId } = props
  const { t } = useTranslation()
  const { podStore, opStore, sessionStore } = useStore()
  const pod:PodClass|null = podStore.pod
  const [ showJoinDialog, setShowJoinDialog ] = useState<UserInfo|null>(null)

  useEffect(() => {
    if ((pod === null) || (pod.podId !== podId) || (pod.status === 'initialized')) {
      podStore.loadPod(podId)
    }
  })

  const issueJoinPod = (userInfo:UserInfo|undefined) => {
    setShowJoinDialog(null)
    if (userInfo) {
      const { userName, color} = userInfo

      if (userName.trim()) {
        const op:Op_addUserToPod = {
          op: 'addUserToPod',
          podId,
          data: {
            podName: 'Unknown Pod',
            createPod: false,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId, // has to be 'private' because we cannot yet determine the pod usergroup of the pod
            userId: sessionStore.session.user.userId,
            login: sessionStore.session.user.login,
            userName: userName.trim(),
            color: color,
            type: 'request',
            keyphrase: '',
          }
        }
        opStore.doOp(op)
      }
    }
  }

  const title = t('Select a Name/Color to Join')

  // no data available
  if (pod === null) return <Infobox>The 'pod' object is null when trying to show pod {podId}</Infobox>

  // wait until podId has been updated in store
  if (pod.podId !== podId) return <Loading info="Waiting for Pod" />

  // pod is loading
  if (pod.status === "loading") return <LoadingPod pod={pod} />

  if (pod.status === 'broken') return <Infobox>
    <Alert severity="error">
      <AlertTitle>{t('Pod Unavailable')}</AlertTitle>
      {t('This pod could not be loaded')}
      </Alert>
      <div style={{textAlign:'center', paddingTop:'1em'}}>
      <Button variant='outlined' onClick={async () => { await podStore.resetPod(pod.podId) }}>Reset</Button>
    </div>
  </Infobox>

  if (pod.status === 'deleted') return <Infobox>
    <Alert severity="error">
      <AlertTitle>{t('Deleted Pod')}</AlertTitle>
      {t('This pod has been deleted. It is no longer available.')}
      </Alert>
      <div style={{textAlign:'center', paddingTop:'1em'}}>
      <Button variant='outlined' onClick={ () => navigate('/') }>Ok</Button>
    </div>
  </Infobox>

  if (pod.status === "unknown") return <Infobox>
    <Alert severity="warning">
      <AlertTitle>{t('Unknown Pod')}</AlertTitle>
      {t('The pod status is unknown, so it either does not exist or you do not have sufficient privileges to view it.')}
    </Alert>
    <div style={{textAlign:'center', paddingTop:'1em'}}>
      <Button variant='outlined' onClick={() => {setShowJoinDialog({userId: sessionStore.session.user.userId, login: sessionStore.session.user.login, userName:sessionStore.session.user.idpProvidedUserName || '', color:'#aaa'})}}>Click to join (if it exists at all)</Button>
      <EditUserInfo title={title} userInfo={showJoinDialog} handleClose={issueJoinPod} />
    </div>
  </Infobox>

  if (pod.status === "requested") return <Infobox>
    <Alert severity="info">
      <AlertTitle>{t('Access Pending')}</AlertTitle>
      {t('You have requested access to this pod, which will be checked once you are back online and have synced all your pending operations.')}
    </Alert>
  </Infobox>

  if (pod.status !== 'loaded') return <Infobox>Pod is {pod.status}</Infobox>

  return <>{props.children}</>;
}

export default observer(PodProvider)