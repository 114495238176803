import { useTranslation } from "react-i18next"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import React, { useEffect } from "react"

type textInputType = {
  type: 'string',
  maxLength?: number
} | {
  type: 'number'
}

// message from thread
const Prompt = ({title, info, open, handleClose, onOk, type, defaultValue }: {title: string, info:string|JSX.Element, open: boolean, handleClose: any, onOk?: any, type?: textInputType, defaultValue?:string|number}) => {
  const [ text, setText ] = React.useState<string>(defaultValue as string)

  const { t } = useTranslation()
  useEffect(() => {
    if (!text && defaultValue) setText(defaultValue as string)
  })

  const textChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var newValue = event.target.value
    if (type && (type.type === 'string') && (type.maxLength)) newValue = newValue.substring(0, type.maxLength)
    if (type && (type.type === 'number')) newValue = (Number(newValue.replace(/[^0-9\.]/g, '')) + '') || ''
    if (newValue) setText(newValue)
  }

  return <Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <p>{info}</p>
      <TextField fullWidth={true} style={{margin:'8px 0'}} label={title} value={text} onChange={textChange} /><br />
    </DialogContent>
    <DialogActions style={{textAlign:'right'}}>
      <Button onClick={() => { handleClose(); setText('') }}>{t('Cancel')}</Button>
      <Button variant='contained' onClick={() => { if (onOk) onOk(text); handleClose(); setText('') }}>{t('OK')}</Button>
    </DialogActions>
  </Dialog>

}

export default Prompt