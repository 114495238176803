import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useStore } from "../../contexts/store"
import { Message as MessageType } from "../../../../types/Message"
import EditMenu from "./EditMenu"
import TextArea from "./TextArea"
import { Box } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'

import { Op_editMessage } from "../../../../types/Ops"
import UserInfoAvatar from "./UserInfoAvatar"
import { PodClass } from "../../classes/Pod"
import TimeFromNow from "./TimeFromNow"
import ViewTracker from "./ViewTracker"
import Reactions from "./Reactions"

// message from thread
const Message = ({content, interactionId, usergroupId}: {content: MessageType, interactionId: string, usergroupId: string}) => {
  const { t } = useTranslation()
  const { sessionStore, uiStore, opStore, podStore } = useStore()
  // check if message is edited
  const isEdited = uiStore.editedMessageId === content.messageId
  const pod:PodClass|null = podStore.pod
  if (!content || !pod || !interactionId || !usergroupId) return null
  // get the interaction which is connected to the message thread
  const interaction   = pod.getInteraction(interactionId)
  if (!interaction) return <div>Could not find interaction</div>
  if(interaction.interactionId === content.messageId) {
    console.warn('an attempt was made to present a comment as a message', interaction)
    return null
  }

  const podId = pod.podId
  const pdfId = interaction.anchor.nodeId
  const interactionType = "message"

  const userInfo = pod.userInfos[content.userId]
  const userId = userInfo ? userInfo.userId : null
  const userName = userInfo ? userInfo.userName : t('unknown')


  function submitEdit(edit: string) {
    // save edit when something has changed
    if((edit !== content.text) && usergroupId) {
      const op:Op_editMessage = {
        op: "editMessage",
        podId: podId,
        data: {
          userId: sessionStore.session.user.userId,
          userName: podStore.userPseudonym || '',
          usergroupId: usergroupId,
          threadId: content.threadId,
          messageId: content.messageId,
          mods: {
            text: edit,
            tModified: dayjs().unix()
          }
        }
      }
      opStore.doOp(op)
    }
    // close edit message
    uiStore.setEditedMessageId(null)
  }

  const EditMessage = () => (
    <TextArea
      handleClose={() => {uiStore.setEditedMessageId(null)}}
      placeholder={t("Edit this comment ...")}
      showHints={true}
      submit={(edit) => submitEdit(edit)}
      value={content.text}
      disabled={!(pod.isAllowed('editMessage', content.messageId))}
    />
  )

  const onHoverMessage = () => {
    // show emoji picker elment
    const emojiPickerElement = document.getElementById(`emoji-picker-${content.messageId}`)
    if(emojiPickerElement) emojiPickerElement.classList.remove("hidden")
  }

  const onHoverOutMessage = () => {
    // hide emoji picker elment
    const emojiPickerElement = document.getElementById(`emoji-picker-${content.messageId}`)
    if(emojiPickerElement) emojiPickerElement.classList.add("hidden")
  }

  return (
    <ViewTracker type={interactionType} id={content.messageId}>
    <Box onMouseOver={onHoverMessage} onMouseOut={onHoverOutMessage} sx={{display: "grid", gridTemplateColumns: "max-content auto", padding: "10px 20px"}} >
      {/* avatar (left) */}
      <Box sx={{marginRight:'15px',justifySelf: "right"}}>
        <UserInfoAvatar sx={{height:36, width:36}} userId={userId} variant='avatar' podId={podId} />
      </Box>
      {/* content (right) */}
      <Box sx={{display: "grid", gridTemplateRows: "max-content auto min-content"}}>
        {/* header */}
        <Box sx={{display: "grid", gridTemplateColumns: "max-content auto min-content", alignItems: "center", marginBottom: "3px", marginLeft: "5px"}}>
          <Box sx={{fontWeight: "600", color: "#4c4c57", wordBreak: "break-word"}}>
            {userName}
          </Box>
          <Box sx={{marginLeft: "15px", color: "#808080a6", fontSize: "13px"}}>
            <TimeFromNow timestamp={content.tModified ? content.tModified*1000 : 0} />
          </Box>
            <EditMenu
              interactionId={content.messageId}
              interactionType={interactionType}
              pdfId={pdfId}
              podId={podId}
              messageId={content.messageId}
              threadId={content.threadId}
            />
        </Box>
        <Box sx={{marginLeft: "5px"}}>
          {/* message */}
          {isEdited && <EditMessage />}
          {!isEdited &&
            <Box sx={{paddingRight: "30px", wordBreak: "break-word", whiteSpace: "pre-line"}}>
              {content.text}
              {content.tCreated !== content.tModified &&
                <Box sx={{
                  alignItems: "center",
                  color: "#b6b6b6",
                  display: "grid",
                  fontSize: "11px",
                  fontStyle: "italic",
                  gridTemplateColumns: "max-content max-content",
                  justifyContent: "end",
                  padding: "2px 10px 0 0"
                }}>
                  <EditIcon sx={{fontSize: "15px"}} /> {t('Edited')}
                </Box>
              }
            </Box>
          }
        </Box>
        <Reactions interactionId={content.messageId} interactionType={interactionType} podId={podId}  reactions={content.reactions} />
      </Box>
    </Box>
    </ViewTracker>
  )
}


export default observer(Message)