import { Box } from "@mui/material"
import pdfMetaStore from "../../../stores/pdfMetaStore"
import { observer } from "mobx-react-lite"

const ActiveThumbnail = ({pageNumber, storeId}: {pageNumber: number, storeId: string}) => {
  const pagePos = pdfMetaStore.getPagePos(storeId)
  if(pagePos === null) return null

  const activePage = Math.round(pagePos[0]) + 1
  const active = (activePage === pageNumber) ? true : false

  return (
    <Box sx={{
      backgroundColor: active ? "#7a78d480" : "",
      display: "grid",
      justifyItems: "center",
      fontStyle: "oblique",
      fontSize: "12px",
    }}>
      {pageNumber}
    </Box>
  )
}

export default observer(ActiveThumbnail)