import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { useStore } from '../../contexts/store'
import { Interaction } from '../../../../types/Interaction'
import InteractionPreview from './InteractionPreview'
import DialogWrapper from './DialogWrapper'

import useEmblaCarousel from 'embla-carousel-react'

import { Box, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import InteractionIcon from './InteractionIcon'

const OverlappingInteractionsMenu = ({podId, pdfId}: {podId: string, pdfId: string}) => {
  const { uiStore } = useStore()
  const deviceWidth = uiStore.deviceWidth
  const isOpen = uiStore.overlappingInteractionMenu.isOpen
  const overlappingInteractions = uiStore.overlappingInteractionMenu.interactionList

  const [ width, setWidth ] = useState(0)
  const measuredRef = useCallback((node: any) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, [])

  const [emblaRef, emblaApi] = useEmblaCarousel({loop: true})
  const scrollPrev = useCallback(() => { if (emblaApi) emblaApi.scrollPrev()  }, [emblaApi])
  const scrollNext = useCallback(() => { if (emblaApi) emblaApi.scrollNext()  }, [emblaApi])
  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )
  const handleClose = () => uiStore.closeOverlappingInteractionMenu()

  if(overlappingInteractions === null) return null

  const selectInteraction = (item: {interaction: Interaction, menuAnchor: any}) => {
    uiStore.closeOverlappingInteractionMenu()
    uiStore.selectInteraction(item, podId, pdfId)
  }

  const Footer = () => (
    <Box sx={{display: "grid", gridTemplateColumns: "max-content auto", margin: "0 20px 20px 20px"}}>
      <Box sx={{display: "grid", gridTemplateColumns: "max-content max-content", alignItems: "center"}}>
        <IconButton size='large' sx={{margin: "5px 2px"}} onClick={scrollPrev} aria-label="scrollPrev" color="primary">
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton size='large' sx={{margin: "5px 2px"}}  onClick={scrollNext} aria-label="scrollNext" color="primary">
          <NavigateNextIcon />
        </IconButton>
      </Box>
      <Dots />
    </Box>
  )

  const Header = () => (
    <DialogTitle sx={{display: "grid", gridTemplateColumns: "auto max-content", padding: "8px 20px", backgroundColor: "whitesmoke"}}>
      <Box sx={{display: "grid", alignItems: "center", justifyContent: "center", color: "grey", fontWeight: 300}}>
        {t('Swipe to choose between interactions')}
      </Box>
      <IconButton aria-label="close" onClick={handleClose}>
        <CloseIcon  />
      </IconButton>
    </DialogTitle>
  )

  const SwipeElement = ({item}: {item: {interaction: Interaction, menuAnchor: any}}) => {
    const interaction = item.interaction
    const interactionType = interaction.interactionType
    const color = (interaction.interactionType === "annotation" && item.interaction.style.color) ? item.interaction.style.color : uiStore.getInteractionColor(item.interaction.interactionType)
    const isHighlight = interactionType === "annotation" && interaction.label === ""

    const onHoverItem = () => {
      // show select label
      const selectLabel = document.getElementById(`select-label-${interaction.interactionId}`)
      if(selectLabel) selectLabel.classList.remove("hidden")
    }

    const onHoverOutItem = () => {
      // hide select label
      const selectLabel = document.getElementById(`select-label-${interaction.interactionId}`)
      if(selectLabel) selectLabel.classList.add("hidden")
    }

    return (
      <Box ref={measuredRef} onClick={() => selectInteraction(item)} sx={{
        flex: "0 0 80%",
        minWidth: 0,
        padding: "20px 30px"
      }}>
        <Box onMouseOver={onHoverItem} onMouseOut={onHoverOutItem} sx={{
          ":hover": {
            cursor: "pointer",
            outline: `1px solid ${color}`,
          }
        }}>
          <Box onClick={() => selectInteraction(item)} sx={{
            alignItems: "center",
            backgroundColor: color,
            borderRadius: "2px",
            display: "grid",
            gridTemplateColumns: "max-content auto max-content",

          }}>
            <IconButton
              aria-label={interactionType}
              size='small'
              sx={{backgroundColor: isHighlight ? color : "white", color: color, margin: "10px 20px",":hover": {backgroundColor: isHighlight ? color : "white"}}}
            >
              <InteractionIcon interactionType={interactionType} />
            </IconButton>
            <Box />
            <Box className="hidden" id={`select-label-${interaction.interactionId}`} sx={{
              backgroundColor: "white",
              borderRadius: "5px",
              color: "#1565c0",
              fontWeight: "500",
              margin: "0 20px",
              padding: "8px",
            }}>
              {t('click to select')}
            </Box>
          </Box>
          <InteractionPreview hideTitle={true} interactionId={item.interaction.interactionId} width={Math.max(40, width-60)} height={deviceWidth === "tiny" ? 300 : 400} size="cover" />
        </Box>
      </Box>
    )
  }

  const Dots = () => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    useEffect(() => {
      if (!emblaApi) return
      emblaApi.on("select", () => setSelectedIndex(emblaApi.selectedScrollSnap()));
    }, [emblaApi, setSelectedIndex])

    return (
      <Box sx={{display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "end", gridGap: "10px", margin: "5px 15px 0 5px"}}>
        {overlappingInteractions.map((item, index) => {
          const interaction = item.interaction
          const interactionType = interaction.interactionType
          const color = (interaction.interactionType === "annotation" && item.interaction.style.color) ? item.interaction.style.color : uiStore.getInteractionColor(item.interaction.interactionType)
          const isHighlight = interactionType === "annotation" && interaction.label === ""
          const isSelected = index === selectedIndex ? true : false
          return (
            <IconButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              size='small'
              sx={{
                color: "white",
                backgroundColor: isSelected ? color : uiStore.getSemiTransparentColor(color),
                height: deviceWidth === "tiny" ? "30px" : "40px",
                width: deviceWidth === "tiny" ? "30px" : "40px",
                padding: "8px",
                ":hover": {
                  border: `2px solid ${color}`,
                  color: color
                }
              }}
            >
            {!isHighlight &&
              <InteractionIcon interactionType={item.interaction.interactionType} />
            }
          </IconButton>
          )}
        )}
      </Box>
    )
  }

  // smartphone view
  if(deviceWidth === "tiny") {
    return (
      <DialogWrapper modus="smartphone" isOpen={isOpen} fullHeight={false} handleClose={handleClose} >
        <Header />
        <Box ref={emblaRef} sx={{overflow: "hidden"}}>
          <Box sx={{display: "flex"}}>
            {overlappingInteractions.map(item =>
              <SwipeElement key={item.interaction.interactionId} item={item} />
            )}
          </Box>
        </Box>
        <Footer />
      </DialogWrapper>
    )
  }
  return (
    <DialogWrapper modus="tablet" isOpen={isOpen} fullHeight={false} handleClose={handleClose} >
      <Header />
      <Box ref={emblaRef} sx={{overflow: "hidden"}}>
        <Box sx={{display: "flex"}}>
          {overlappingInteractions.map(item =>
            <SwipeElement key={item.interaction.interactionId} item={item} />
          )}
        </Box>
      </Box>
      <Footer />
    </DialogWrapper>
  )
}

export default observer(OverlappingInteractionsMenu)
