import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../contexts/store"
import { useParams } from "../../helper/Helper"
import { iReadingQuestion } from "../../../../types/Interaction"
import ViewTracker from "./ViewTracker"
import MessageArea from "./MessageArea"
import Message from "./Message"
import dayjs from 'dayjs'

import { Alert, Box, Divider } from "@mui/material"
import { Op_addMessage } from "../../../../types/Ops"
import { Message as Messagetype } from "../../../../types/Message"
import OpenUntilIcon from '@mui/icons-material/ScheduleSend'
import AnswerVisibilityIcon from '@mui/icons-material/Visibility'
import AnswerVisibilityDelayIcon from '@mui/icons-material/EventNote'

const ReadingQuestion = ({readingQuestion}: {readingQuestion: iReadingQuestion}) => {
  const { t } = useTranslation()
  const { podId } = useParams()
  const { podStore, opStore, sessionStore } = useStore()
  const { session } = sessionStore
  // get thread from actual selected reading question
  const thread = podStore.getThread(readingQuestion.interactionId)

  // show error if thread was not created
  if(!thread) return (
    <Box sx={{ margin: "10px", borderRadius: "5px" }}>
      <Alert severity="error">
        {t('Unfortunately there was an error when creating the reading question')}
      </Alert>
    </Box>
  )

  const threadIsVisible = () => {
    const answerVisibility = readingQuestion.answerVisibility
    const answerVisibilityDelay = readingQuestion.answerVisibilityDelay
    const isAfterDelay = answerVisibilityDelay ?  dayjs().isAfter(dayjs.unix(answerVisibilityDelay)) : true
    // instructor always see thread
    if(readingQuestion.userId === session.user.userId) {
      return true
    }
    // users see thread if it is visible and public or if they have already replied
    if(isAfterDelay){
      if(answerVisibility === 'public' || (answerVisibility === 'afterReply' && getUserMessage())) return true
    }
    return false
  }

  function getUserMessage() {
    if(thread) {
      const userMessage = thread.messages.find(message => message.userId === session.user.userId)
      if(userMessage) return userMessage
    }
    return null
  }

  function isAfterDeadline() {
    return readingQuestion.openUntil ? dayjs().isAfter(dayjs.unix(readingQuestion.openUntil)) : false
  }

  function submitInput(input: string) {
    // create message inside reading question thread, if there is no answer from this user yet
    if(thread && !getUserMessage() && !isAfterDeadline()) {
      opStore.doOp({
        op: "addMessage",
        podId: podId,
        data: {
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
          messageId: sessionStore.createUuid(),
          threadId: thread.threadId,
          refMessageId: null,
          coid: null,
          userId: session.user.userId,
          userName: podStore.userPseudonym,
          text: input,
          reactions: {},
          tCreated: dayjs().unix(),
          tModified: dayjs().unix()
        } as Messagetype
      } as Op_addMessage)
    }
  }

  // list of thread messages
  const threadMessages: any = []
  if(thread) {
    thread.messages.forEach(message => {
      // add all messages that are not from the user to the list
      if(message.userId !== session.user.userId) {
        threadMessages.push(
          <li id={message.messageId} key={message.messageId} style={{scrollMarginTop: "200px"}}>
            <Message content={message} interactionId={readingQuestion.interactionId} usergroupId={thread.usergroupId} />
          </li>
        )
      }
    })
  }

  const ThreadMessages = observer(() => {
    if(threadMessages.length > 0 && threadIsVisible()) return (
      <Box>
        <Divider variant="middle" sx={{marginBottom: "15px"}} />
        <ul style={{listStyleType: "none", padding: "0 5px", margin: 0}}>
          {threadMessages}
        </ul>
      </Box>
    )
    return null
  })

  const ReadingQuestionInfo = observer(() => {
    const answerVisibility = readingQuestion.answerVisibility ? readingQuestion.answerVisibility : null
    const openUntil = readingQuestion.openUntil ? dayjs.unix(readingQuestion.openUntil).format('lll') : null
    const answerVisibilityDelay = readingQuestion.answerVisibilityDelay ? dayjs.unix(readingQuestion.answerVisibilityDelay).format('lll') : null
    return (
      <Alert severity="info" sx={{ margin: "0 10px 15px 10px", fontSize: "14px" }}>
        {openUntil &&
          <Box sx={{display: "grid", gridTemplateColumns: "min-content auto", alignItems: "center", gridGap: "5px", fontWeight: 300, color: isAfterDeadline() ? "red" : "green"}}>
            <OpenUntilIcon sx={{ fontSize: "18px" }} />
            {t('Answers can be submitted until {{date}}', {date: openUntil})}
          </Box>
        }
        {answerVisibilityDelay &&
          <Box sx={{display: "grid", gridTemplateColumns: "min-content auto", alignItems: "center", gridGap: "5px", fontWeight: 300}}>
            <AnswerVisibilityDelayIcon sx={{ fontSize: "18px" }} />
            {t('Answers from others visible from {{date}}', {date: answerVisibilityDelay})}
          </Box>
        }
        {answerVisibility &&
          <Box sx={{display: "grid", gridTemplateColumns: "min-content auto", alignItems: "center", gridGap: "5px", fontWeight: 300}}>
            <AnswerVisibilityIcon sx={{ fontSize: "18px" }} />
            {t('Answer visibility')}: {t(answerVisibility)}
          </Box>
        }
      </Alert>
    )
  })

  const userMessage = getUserMessage()
  const numberOfReplies = thread.messages.length
  const afterDeadline = isAfterDeadline()
  return (
    <ViewTracker type="readingQuestion" id={readingQuestion.interactionId}>
      <Box sx={{ display: "grid", gridTemplateRows: "max-content max-content max-content"}}>
        <ReadingQuestionInfo />
        {/* show message area if user not already replied, otherwise show answer as message */}
        {userMessage ?
          <Message content={userMessage} interactionId={readingQuestion.interactionId} usergroupId={thread.usergroupId} />
          :
          !afterDeadline &&
            <Box sx={{ margin: "10px 0"}}>
              <MessageArea placeholder={t('reply')} disabled={false} submitInput={submitInput} value={null} key={readingQuestion.interactionId} />
              {numberOfReplies > 0 &&
                <Box sx={{marginLeft: "25px", marginTop: "-10px", color: "grey", fontWeight: 400, fontSize: "12px"}}>
                  {(numberOfReplies === 1) ?
                    t('One person has already replied') :
                    numberOfReplies +' ' + t('people have already replied')
                  }
                </Box>
              }
            </Box>
        }
        {/* show if answer was successful */}
        {userMessage &&
          <Alert severity="success" sx={{ margin: "0px 10px 0px 10px", fontSize: "14px" }}>
            {t('Answered successfully')}
          </Alert>
        }
        {/* thread with messages */}
        <ThreadMessages />
      </Box>
    </ViewTracker>
  )
}

export default observer(ReadingQuestion)