import { MouseEvent } from "react"
import { Box, Button } from "@mui/material"
import { useStore } from "../../contexts/store"
import { useTranslation } from "react-i18next"
import BlockquoteBox from "./BlockquoteBox"

import SouthIcon from '@mui/icons-material/South';
import { navigateToInteraction, useParams } from "../../helper/Helper"
import ViewTracker from "./ViewTracker"
import { observer } from "mobx-react-lite"

const FollowLink = ({linkId, pageListRef, podId, pdfId, storeId}: {linkId: string, pageListRef: any, podId: string, pdfId: string, storeId: string}) => {
  const { t } = useTranslation()
  const { podStore } = useStore()
  const params = useParams()
  const pod = podStore.pod
  if (!pod) return null

  const linking = pod.content.pdfFiles[pdfId].links[linkId]

  const target = pod.getLinkOther(linking)
  if (!target) return null

  const label = linking.label
  const dstFilename = podStore.getFilename(target.anchor.nodeId)
  const sameSiteLink = target.anchor.nodeId === params.nodeId ? true: false

  const handleFollowLink = (event: React.MouseEvent<Element>) => {
    event.stopPropagation()
    // prevent navigating to the url where we already are
    if(params.podId === podId && params.pdfId === target.anchor.nodeId && params.interactionType === "link" && params.interactionId === target.interactionId) return
    navigateToInteraction(pdfId, podId, target.interactionId, "link", target.anchor.nodeId)
  }

  return (<ViewTracker type='link' id={params.interactionId}>
    <div style={{textAlign:'center'}}>
      <SouthIcon />
    </div>
    <Box sx={{padding: "20px", display: "grid", gridTemplateRows: "min-content min-content"}}>
      <Box sx={{padding: "15px", margin: "0 5px 5px 0", color: "#000556", fontWeight: "300", fontSize: "17px", border:'1px solid black', borderRadius: 4}}>
        {label}
      </Box>
    </Box>
    <div style={{textAlign:'center'}}>
      <SouthIcon />
    </div>
    <Box>
      {!sameSiteLink &&
        <Box sx={{m: "25px 15px 5px 15px", fontWeight: "bold", wordBreak: "break-all"}}>
          {dstFilename}
        </Box>
      }
      <BlockquoteBox interaction={target} />
    </Box>
    <Box sx={{display: "grid", gridTemplateColumns: "1fr", p: "10px 5px 5px 5px"}}>
      <Button onClick={(event: MouseEvent<Element>) => handleFollowLink(event)} variant="contained" size="small">
        {sameSiteLink &&
          t('Follow Link to other section')
        }
        {!sameSiteLink &&
          t('Follow Link to other pdf')
        }
      </Button>
    </Box>
    </ViewTracker>)
}

export default observer(FollowLink)