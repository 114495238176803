import { observer } from 'mobx-react-lite'
import { useStore } from '../../../contexts/store'
import { useTranslation } from 'react-i18next'
import { createRef, useState } from 'react'
import { useParams } from '../../../stores/uiStore'
import { InteractionType, iAnnotation, iComment, iEmotion, iLink, iReadingQuestion, iTag, iWeblink } from '../../../../../types/Interaction'
import { OpCode } from '../../../../../types/OpCodes'
import { baseUrl } from "../../../api/api"

import InteractionModalComment from './InteractionModalComment'
import InteractionModalTag from './InteractionModalTag'
import InteractionModalWeblink from './interactionModalWeblink'
import InteractionModalEmotion from './InteractionModalEmotion'
import InteractionModalAnnotation from './InteractionModalAnnotation'
import InteractionModalLink from './InteractionModalLink'
import InteractionModalReadingQuestion from './InteractionModalReadingQuestion'
import EditInteractionBlockquote from './EditInteractionBlockquote'
import Viewer from '../Viewer'
import DialogWrapper from '../../Elements/DialogWrapper'
import InteractionOpHeader from '../../Elements/InteractionOpHeader'
import AddLinkModal from './AddLinkModal'
import FreehandButton from '../Toolbar/FreehandButton'

import { Box, Button, DialogContent, DialogTitle } from '@mui/material'


const EditInteractionModal = () => {
  const { t } = useTranslation()
  const { sessionStore, uiStore, podStore } = useStore()
  const { podId, pdfId } = useParams()
  const { session } = sessionStore
  const deviceWidth = uiStore.deviceWidth
  // on tiny screen: show or hide fullscreen text selection editor
  const [showEditSelection, setShowEditSelection] = useState(false)
  const viewerListRef: any = createRef()
  const pdfUrl = `${baseUrl}/pdf/${pdfId}`
  const thumbnailUrl = `${baseUrl}/pageView/${pdfId}`

  // if isOpen is true, show modal
  const isOpen = uiStore.interactionModal.isOpen
  // isTransformation = true: annotation is transformed into another interaction
  // isTransformation = false: existing interaction is changed
  const isTransformation = uiStore.interactionModal.isTransformation
  const selectedTransformOperation = uiStore.getSelectedOperation()
  // get interaction data
  const interactionId = uiStore.interactionModal.interactionId

  const interaction = interactionId ? podStore.getInteraction(pdfId, interactionId) : null
  // check if pdf, pod and interaction is loaded
  if(pdfId === null || podId === null || interaction === null) return null

  // get interaction anchor
  const anchor = interaction.anchor
  // get interaction color
  let color = uiStore.getInteractionColor(isTransformation ? selectedTransformOperation as OpCode : interaction.interactionType as InteractionType)
  if((isTransformation && selectedTransformOperation === "addAnnotation" && interaction.style.color) ||
     (!isTransformation && interaction.interactionType === 'annotation' && interaction.style.color)) {
    color = interaction.style.color
  }
  // get interaction op
  const interactionOp: OpCode | null = isTransformation ? uiStore.getSelectedOperation() : getInteractionOp(interaction.interactionType)
  function getInteractionOp(interactionType: InteractionType) {
    if(interactionType === "annotation") return "editAnnotation"
    if(interactionType === "comment") return"editComment"
    if(interactionType === "link") return "editLink"
    if(interactionType === "tagging") return "editTagging"
    if(interactionType === "weblink") return "editWeblink"
    if(interactionType === "emotion") return "editEmotion"
    if(interactionType === "readingQuestion") return "editReadingQuestion"
    else {
      console.warn("unexprected interactionType in getInteractionOp", interactionType)
      return null
    }
  }

  if(anchor === null || interactionOp === null) return null

  // add Links needs to create a second interaction first
  if(interactionOp === "addLink") return <AddLinkModal anchor={anchor} interaction={interaction as iAnnotation} />

  const handleClose = () => {
    // close text selection modal
    if(showEditSelection) setShowEditSelection(false)
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
    // delete selected op in store
    uiStore.setSelectedOperation(null)
    // remove edited text selection
    podStore.deleteInteractionEditAnchor(interaction.interactionId)
    if(interaction.interactionType === 'link') {
      // delete link label
      uiStore.deletePreviewLinkLabel(interaction.interactionId)
      // delete edit anchor and link label on other link side
      const pod = podStore.pod
      if(pod) {
        const targetLink = pod.getLinkOther(interaction)
        if(targetLink) {
          podStore.deleteInteractionEditAnchor(targetLink.interactionId)
          uiStore.deletePreviewLinkLabel(targetLink.interactionId)
        }
      }
    }
  }

  const editSelectedText = () => {
    setShowEditSelection(true)
  }

  const InteractionModalContent = () => {
    switch (interactionOp) {
      case "addAnnotation":
      case "editAnnotation":
        return <InteractionModalAnnotation anchor={anchor} interaction={interaction as iAnnotation} />
      case "addComment":
      case "editComment":
        return <InteractionModalComment anchor={anchor} interaction={interaction as iComment} />
      case "editLink":
        return <InteractionModalLink anchor={anchor} interaction={interaction as iLink} />
      case "addTagging":
      case "editTagging":
        return <InteractionModalTag anchor={anchor} interaction={interaction as iTag} podId={podId} session={session}/>
      case "addWeblink":
      case "editWeblink":
        return <InteractionModalWeblink anchor={anchor} interaction={interaction as iWeblink} />
      case "addEmotion":
      case "editEmotion":
        return <InteractionModalEmotion anchor={anchor} interaction={interaction as iEmotion} />
      case "addReadingQuestion":
      case "editReadingQuestion":
        return <InteractionModalReadingQuestion anchor={anchor} interaction={interaction as iReadingQuestion} />
      default:
        console.warn(`${interactionOp} does not match in Editinteraction`)
        return null
    }
  }

  const Title = () => (
    <DialogTitle sx={{padding: 0}}>
      <InteractionOpHeader color={color} interactionId={interactionId} interactionOp={interactionOp} handleClose={handleClose} />
    </DialogTitle>
  )

  const EditFreehandButton = () => (
    <Box sx={{
      backgroundColor: "white",
      borderBottomRightRadius: "5px",
      boxShadow: 3,
      padding: "4px",
      position: "absolute"
    }}>
      <FreehandButton />
    </Box>
  )

  // if add operation: show Blockquote
  // if edit operation: show Viewer
  const TextSelection = () => {
    if(interactionId) return (
      <Box sx={{
        display: "grid",
        gridTemplateRows: "1fr",
        height: "100%"
      }}>
        <Viewer
          interactionEditId={interactionId}
          pageListRef={viewerListRef}
          pdfId={pdfId}
          pdfUrl={pdfUrl}
          podId={podId}
          storeId={`edit_${interactionId}`}
          thumbnailUrl={thumbnailUrl}
        />
        <EditFreehandButton />
      </Box>
    )
    // else
    return (
      <Box sx={{
        display: "grid",
        gridTemplateRows: "min-content",
        margin: "10px 40px 20px 40px"
      }}>
        <EditInteractionBlockquote  color={color} interactionId={interactionId} anchor={anchor} />
      </Box>
    )
  }

  // show dialog in fullscreen, switch between edit attributes and edit text selection
  if(deviceWidth === "tiny") {
    // show edit text selection with viewer
    if(showEditSelection && interactionId) return (
      <DialogWrapper modus="smartphone" isOpen={showEditSelection} fullHeight={false} handleClose={() => setShowEditSelection(false)} >
        <Title />
        <Box sx={{
          display: "grid",
          gridTemplateRows: "1fr min-content",
          height: "100%"
        }}>
          <Viewer
            interactionEditId={interactionId}
            pageListRef={viewerListRef}
            pdfId={pdfId}
            pdfUrl={pdfUrl}
            podId={podId}
            storeId={`edit_${interactionId}`}
            thumbnailUrl={thumbnailUrl}
          />
          <EditFreehandButton />
          <Box sx={{display: "flex", gridGap: "10px", flexWrap: "wrap", margin: "12px"}}>
            <Button sx={{ backgroundColor: "white", flex: 1 }} variant="outlined" onClick={() =>{ setShowEditSelection(false); podStore.deleteInteractionEditAnchor(interactionId)}}>{t('Reset')}</Button>
            <Button sx={{ flex: 1 }} variant="contained" onClick={() => setShowEditSelection(false)}>{t('OK')}</Button>
          </Box>
        </Box>
      </DialogWrapper>
    )
    // else: show edit interaction attributes
    return (
      <DialogWrapper modus="smartphone" isOpen={isOpen} fullHeight={false} handleClose={handleClose} >
        <Title />
        <DialogContent sx={{ display: "grid", gridTemplateRows: "min-content auto", backgroundColor: "white"}}>
          <Box sx={{
              backgroundColor: "#e8e8e8",
              border: "1px solid #c1c1c1",
              borderRadius: "5px",
              display: "grid",
              margin: "34px 0 35px 0",
            }}>
            <EditInteractionBlockquote  color={color} interactionId={interactionId} anchor={anchor} />
            <Button sx={{display: interactionId ? "grid" : "none"}}  onClick={editSelectedText} variant='contained' size='small'>{t('Edit')}</Button>
          </Box>
          <InteractionModalContent />
        </DialogContent>
      </DialogWrapper>
    )
  }

  // show edit attributes and edit text selection together as one row
  // do not use large screen, if adding new interaction (will be changed in future)
  if(!interactionId || deviceWidth === 'smaller' || deviceWidth === "small" || deviceWidth === "medium") {
    return (
      <DialogWrapper modus="tablet" isOpen={isOpen} fullHeight={interactionId ? true : false} handleClose={handleClose} >
        <Title />
        <DialogContent sx={{ display: "grid", gridTemplateRows: "1fr auto", backgroundColor: "white", padding: 0}}>
          <TextSelection />
          <Box sx={{ margin: "5px 10px 10px 10px", padding: "0 10px" }}>
            <InteractionModalContent />
          </Box>
        </DialogContent>
      </DialogWrapper>
    )
  }

  // show edit attributes and edit text selection side by side
  return (
    <DialogWrapper modus="desktop" isOpen={isOpen} fullHeight={interactionId ? true : false} handleClose={handleClose} >
      <Title />
      <DialogContent sx={{ display: "grid", gridTemplateColumns: "1fr auto", backgroundColor: "white", padding: 0, margin: "10px"}}>
        <TextSelection />
        <Box sx={{ margin: "5px 10px 10px 10px", minWidth: "500px", display: "grid", alignContent: "center" }}>
          <InteractionModalContent />
        </Box>
      </DialogContent>
    </DialogWrapper>
  )
}

export default observer(EditInteractionModal)
