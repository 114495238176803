import { useTranslation } from 'react-i18next'
import { ChangeEvent, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import TextField from './TextField'
import SaveCancel from './SaveCancel'

const TextArea = (
  props: {
    handleClose: () => void
    placeholder: string,
    showHints: boolean,
    submit: (input: string) => void,
    value: string,
    disabled: boolean,
  }) => {

  const { handleClose, placeholder, showHints, submit, value, disabled } = props
  const { t } = useTranslation()
  const [input, setInput] = useState("")

  useEffect(() => {
    // if a value was given, pass it to the input field
    if(value) {
      setInput(value)
    }
  }, [value])

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setInput(e.target.value)
  }

  function doSubmit() {
    if(input !== "") {
      submit(input)
      setInput("")
    }
  }

  return (
    <Box sx={{display: "grid", gridTemplateColumns: "auto", gridTemplateRows: "min-content min-content auto",  alignItems: "end"}}>
      <Box sx={{ display: "grid", margin: "5px 10px 0 10px" }}>
        <TextField
          handleInputChange={handleInputChange}
          handleClose={handleClose}
          placeholder={placeholder}
          minRows={showHints ? 1 : 3}
          submit={doSubmit}
          value={input}
          disabled={disabled}
        />
      </Box>
        <Box sx={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "10px",
          }}
        >
        {showHints &&
          <Box sx={{alignSelf: "center", pl: "15px", fontSize: "11px", color: "grey", fontStyle: "oblique"}}>
            <b>{t('Shift')}</b> + <b>{t('Enter')}</b> {t('for linebreaks')}, <b>Esc</b>  {t('to cancel')}
          </Box>
        }
      </Box>
      <SaveCancel handleSave={doSubmit} handleClose={handleClose} />
    </Box>
  )
}

export default TextArea
