import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'wouter'
import { useStore } from '../../contexts/store'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import { Box, Button, Divider, ListItemIcon, ListItemText, Paper } from '@mui/material'
import { IconButton } from '@mui/material'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReplayIcon from '@mui/icons-material/Replay';
import { env } from '../../api/api'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

import { Pod, Usergroup } from '../../../../types/Pod'
import PodImpro from '../Dev/PodImpro'              // @hagen: bitte bis auf weiteres drin lassen. I know it's ugly, aber solange ich Deinen branch immer wieder bei mir reinmerge, ist das doof sonst :)
import { Op_addUserToPod, Op_deletePod, Op_removeUserFromPod } from '../../../../types/Ops'
import { PodClass } from '../../classes/Pod'
import ConfirmDialog from '../Elements/ConfirmDialog'
import { DeleteForever } from '@mui/icons-material'
import EditUserInfo from '../Pod/EditUserInfo'
import { UserInfo } from '../../../../types/User'
import AddPod from '../Pod/AddPod'

const Home: React.FC = () => {
  const { t } = useTranslation()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore
  const pods = session.pods
  const [ anchorEl, setAnchorEl ] = React.useState<null | Element>(null)
  const [ activePod, setActivePod ] = React.useState<null|Pod>(null)
  const [ confirmPodReset, setConfirmPodReset ] = React.useState<null|string>(null)
  const [ showDeleteDialog, setShowDeleteDialog ] = React.useState<Pod|null>(null)
  const [ createNewPod, setCreateNewPod ] = React.useState<boolean>(false)
  const [ newPodName, setNewPodName ] = React.useState<string>('')
  const [ userInfo, setUserInfo ] = React.useState<UserInfo|null>(null)
  const navigate = useLocation()[1]
  const ownPods = pods.filter(p => p.creator?.userId === session.user.userId).length

  const deviceWidth = uiStore.deviceWidth
  const containerPadding = (deviceWidth === "tiny" || deviceWidth === 'smaller') ? 16 : 40

  useEffect(() => {
    podStore.unsetPod()
  })

  const handleClick = (event: React.MouseEvent<Element>, pod:Pod) => {
    setAnchorEl(event.currentTarget)
    setActivePod(pod)
    event.stopPropagation()
  }

  const handleClose = () => {
    setAnchorEl(null)
    setActivePod(null)
  }

  const leavePod = (userId:number, podId:string) => {
    const p = sessionStore.session.pods.find(p => p.podId === podId)
    if (p) {
      const pod = new PodClass(p)
      const op:Op_removeUserFromPod = {
        op: 'removeUserFromPod',
        podId: podId,
        data: {
          usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
          userId,
          userName: pod.userInfos[userId].userName || '',
        }
      }
      opStore.doOp(op)
    }
  }

  const deletePod = (podId:string) => {
    if (!podId) return
    const pod = session.pods.find(p => p.podId === podId)
    if (!pod) return
    const podUsergroupId = Object.keys(pod.usergroups).find((id) => pod.usergroups[id].role === 'Pod')
    if (!podUsergroupId) return

    const op:Op_deletePod = {
      op: 'deletePod',
      podId: podId,
      data: {
        userId: sessionStore.session.user.userId,
        userName: pod.userInfos[sessionStore.session.user.userId].userName || '',
        usergroupId: podUsergroupId,
      }
    }
    opStore.doOp(op)
  }

  const doCreatePod = (info:UserInfo) => {
    if ((newPodName) && (info) && (info.userName)) {
      const op:Op_addUserToPod = {
        op: 'addUserToPod',
        podId: sessionStore.createUuid(),
        data: {
          podName: newPodName,
          createPod:true,
          usergroupId: '',
          userId: sessionStore.session.user.userId,
          login: sessionStore.session.user.login,
          userName: info.userName,
          color:info.color,
          type: 'request',
          keyphrase: 'CREATE'
        }
      }
      opStore.doOp(op)
    }

    setCreateNewPod(false)
    setUserInfo(null)
  }

  const suggestReset = (e:React.MouseEvent, podId:string) => {
    const pod = sessionStore.session.pods.find((pod:Pod) => pod.podId === podId)
    if (pod && pod.outOfSync) {
      setConfirmPodReset(podId)
      e.preventDefault()
      e.stopPropagation()
    }
  }

  // TODO: create message if there are no pods available
  if(pods === null) return <div>no pods available</div>

  const sx = {
    height: "50px",
    display: "grid",
    gridTemplateColumns: "min-content auto min-content min-content",
    gridGap: "10px",
    alignContent: "top",
    p: 2,
    mt: 1,
    mb: 1,
    "&:hover": {
      backgroundColor: "whitesmoke"
    }
  }

  const podsList = pods.slice().sort((p1:Pod, p2: Pod) => { return ('' + p1.name).localeCompare(p2.name); }).map((pod: Pod) => {
    let status = null

    if (pod.outOfSync) status = <Box title={t('This pod seems to be out of sync. It is recommended that you reset it. Click for more.')}><SyncProblemIcon color='warning' /></Box>
    return  <li key={pod.podId}>
      <Link to={`pod/${pod.podId}/contents`} onClick={(e) => suggestReset(e, pod.podId)} style={{textDecoration: "none"}}>
        <Paper elevation={2} sx={sx}>
          <FolderSpecialIcon />
          <div style={{overflow:'hidden'}}>
          {pod.name}
          {pod.status === 'requested' ? <span>&nbsp;(access requested)</span> : null}
          </div>
          {status}
          <IconButton size='small' onClick={(e:React.MouseEvent) => handleClick(e, pod) }>
            <MoreHorizIcon fontSize='small' />
          </IconButton>
        </Paper>
      </Link>
    </li>
  })

  return (
    <Box className="overflowWrapper" id="pod-container" sx={{
      display: "grid",
      gridTemplateRows: "max-content auto",
    }}>
      <Box sx={{backgroundColor: "white"}}>
        <Box sx={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: `${1280 + (2*containerPadding)}px`,
          paddingTop: `${(deviceWidth === 'medium' || deviceWidth === 'large') ? 40 : 0}px`,
          paddingLeft: `${containerPadding}px`,
          paddingRight: `${containerPadding}px`,
          marginBottom: "5px"
        }}>
        <Box sx={{ display: "grid", gridTemplateRows: "auto", alignContent: "start", p: 5 }}>
          <h2>{t('Welcome to SHRIMP')}</h2>
          <ul style={{listStyleType: "none", padding: 0}}>{podsList}</ul>
        </Box>
        <Box sx={{ p: 5 }} style={{textAlign:'right'}} title={t('You can create {{nof}} more new pods', {nof: (session.user.podLimit - ownPods)})}>
          <Button variant="outlined" onClick={() => { setCreateNewPod(true) }} disabled={session.user.podLimit <= ownPods}>{t('Create New Pod')}</Button>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText onClick={(e) => { if (activePod?.podId) leavePod(sessionStore.session.user.userId, activePod?.podId); handleClose(); e.stopPropagation() }}>{t('Leave this pod')}</ListItemText>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <ReplayIcon />
            </ListItemIcon>
            <ListItemText onClick={(e) => { if (activePod?.podId) podStore.resetPod(activePod?.podId); handleClose(); e.stopPropagation() }}>{t('Reload this pod')}</ListItemText>
          </MenuItem>

          { (activePod?.creator?.userId === sessionStore.session.user.userId) ? <nav key="deldiv" aria-label="dangerous delete option">
            <Divider style={{margin:'8px 0'}} />
            <MenuItem key='delete' onClick={(e:React.MouseEvent) => { setShowDeleteDialog(activePod); handleClose(); e.stopPropagation() }}>
              <ListItemIcon>
                <DeleteForever sx={{color:"darkred"}} />
              </ListItemIcon>
              <ListItemText sx={{color:"darkred"}}>{t('Delete this pod')}</ListItemText>
            </MenuItem>
          </nav> : null }

        </Menu>
        <ConfirmDialog
          title={t('Pod Out of Sync')}
          info={t('The Pod seems to be out of sync. To make sure') as string}
          open={Boolean(confirmPodReset)}
          handleClose={() => { setConfirmPodReset(null); navigate(`pod/${confirmPodReset}/contents`) }}
          onOk={() => { if (confirmPodReset) podStore.resetPod(confirmPodReset) }}
        />
        <ConfirmDialog title={t('Permanently Delete this Pod?')}
          info={t('Deleting this pod \"{{podname}}\" will also delete all files, interactions, and conversations it contains. All contents of the pod will be lost. This cannot be undone. Are you sure?', {podname: showDeleteDialog?.name}) ||''}
          open={Boolean(showDeleteDialog)}
          handleClose={() => { setShowDeleteDialog(null)} }
          onOk={() => deletePod(showDeleteDialog?.podId || '')} />
        <AddPod open={createNewPod === true} handleClose={() => setCreateNewPod(false)} />
        <EditUserInfo userInfo={userInfo} handleClose={doCreatePod} />
        {env.dev ? <PodImpro /> : null}
      </Box>
    </Box>
  </Box>
  )

}

export default observer(Home)