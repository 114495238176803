import React from "react"
import { Card } from '@mui/material'

const Infobox = (props:any) => {

  return <Card elevation={12} sx={{
    justifySelf: "center",
    alignSelf: "center",
    padding: "25px",
    maxWidth: 500,
  }}>
      {props.children}
  </Card>
}

export default Infobox