import { observer } from "mobx-react-lite"
import { Box, Chip } from "@mui/material"
import { useStore } from "../../contexts/store"
import { iTag } from "../../../../types/Interaction"
import BlockquoteBox from "./BlockquoteBox"

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react"
import { navigateToInteraction, useParams } from "../../helper/Helper"
import ViewTracker from "./ViewTracker"
import TaggingChip from "./TaggingChip"

const Tagging = ({pageListRef, podId, pdfId, storeId, tag}: {pageListRef: any, podId: string, pdfId: string, storeId: string, tag: iTag}) => {
  const { podStore } = useStore()
  const [expanded, setExpanded] = useState<String|false>(tag.anchor.nodeId)
  const params = useParams()
  const pod = podStore.pod
  if (!pod) return null

  // only show tag if there is a tag property
  const tagProp = podStore.getTagProp(tag.tagId)
  if(!tagProp) return null

  const handleChange = (panel: String) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleNavigate = (event: React.MouseEvent<Element>, nodeId: string, foundTag: any) => {
    event.stopPropagation()
    // prevent navigating to the url where we already are
    if(params.podId === podId && params.pdfId === nodeId && params.interactionType === "tagging" && params.interactionId === foundTag.interactionId) return
    navigateToInteraction(pdfId, podId, foundTag.interactionId, "tagging", nodeId)
  }

  const tags: {[nodeId: string]: JSX.Element[]} = {}
  const thisNodeId: string = tag.anchor.nodeId

  for(const nodeId in pod.content.pdfFiles) {
    for(const interactionId in pod.content.pdfFiles[nodeId].taggings) {
      const foundTag = pod.content.pdfFiles[nodeId].taggings[interactionId]
      const opacity = (foundTag.interactionId === tag.interactionId) ? .5 : 1
      if (foundTag.tagId === tag.tagId) {
        if (typeof tags[nodeId] === 'undefined') tags[nodeId] = []
        tags[nodeId].push(<Box style={{opacity}} key={foundTag.interactionId} onClick={(event: React.MouseEvent<Element>) => handleNavigate(event, nodeId, foundTag)}><BlockquoteBox interaction={foundTag}/></Box>)
      }
    }
  }

  //const nodeIdsCurrentOneFirst = [thisNodeId, ...Object.keys(tags).filter(nodeId => nodeId !== thisNodeId)]
  const nodeIds = Object.keys(tags)
  nodeIds.sort((nodeId_A, nodeId_B) => {
    if (pod.content.pdfFiles[nodeId_A].weight > pod.content.pdfFiles[nodeId_B].weight) return 1
    if (pod.content.pdfFiles[nodeId_A].weight < pod.content.pdfFiles[nodeId_B].weight) return -1
    return 0
  })

  return (<ViewTracker type="tagging" id={params.interactionId}>
    <Box sx={{margin: "20px", fontWeight: "bold"}}>
      <TaggingChip interaction={tag} />
    </Box>
    {nodeIds.map(nodeId => {
        return <Accordion key={nodeId} expanded={expanded === nodeId} onChange={handleChange(nodeId)} style={{margin:0}}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{borderTop:'1px solid #000', borderBottom:'1px solid #000', backgroundColor:'#e8e8e8', marginTop:8}}>
            <strong>{tags[nodeId].length}x in {nodeId === thisNodeId ? 'this document' : '"' + pod.content.pdfFiles[nodeId].name + '"'}</strong>
          </AccordionSummary>
          <AccordionDetails>{tags[nodeId]}</AccordionDetails>
        </Accordion>
      })}
  </ViewTracker>)
}

export default observer(Tagging)