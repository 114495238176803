import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import { useStore } from '../../contexts/store'
import { useTranslation } from 'react-i18next'
import { Box, Alert, Button } from '@mui/material'
import api from '../../api/api'
import { idbStoredOp } from '../../../../types/Ops'
import Operation from '../Pod/Activity/Operation'
import TimeFromNow from '../Elements/TimeFromNow'


const Syncing: React.FC = () => {
  const pendingOps: idbStoredOp[] = []
  const { t } = useTranslation()
  const { broadcastStore } = useStore()
  const { syncQueueLength, lastSyncTimestamp, lastSyncStatus } = broadcastStore.serviceWorkerStatus

  const [opLog, setOpLog] = useState(pendingOps)

  const fetchData = async () => {
    if ((syncQueueLength) && (opLog.length !== syncQueueLength)) {
      const res = await api.getPendingOps()
      if (res) setOpLog(res); else setOpLog([])
    }
    return null
  }

  useEffect(() => {
    fetchData().catch(console.error)
  })


  const doReset = () => {
    api.reportError('Queue Reset', JSON.stringify(opLog))
    api.resetCache({idbOpLog: true})
  }

  const statusIndicator = (status:number) => {
    switch(status) {
      case 200:
        return <Alert severity="success">{status} ({t('OK')})</Alert>
      case 400:
        return <Alert severity="warning">{status} ({t('The server has rejected one or more operations')})</Alert>
      case 500:
        return <Alert severity="warning">{status} ({t('Sync Failed: Server malfunctioned')})</Alert>
      case 502:
        return <Alert severity="warning">{status} ({t('Sync Failed: Could not reach server')})</Alert>
      default:
        return `${status} (${t('Unknown Status')})`
    }
  }

  const timestampIndicator = (timestamp:number) => {
    const lapsed = dayjs().unix() - timestamp
    const time = timestamp < 1600000000 ? t('never') : <TimeFromNow timestamp={timestamp*1000} />
    if (lapsed<30) return <Alert severity="success">{time}</Alert>
    if (lapsed<300) return <Alert severity="info">{time}</Alert>
    return <Alert severity="warning">{time}</Alert>
  }

  const pendingOperations = () => {
    if (syncQueueLength === 0) return null
    var offerCleaning = null
    if ((lastSyncStatus === 400) || (lastSyncStatus === 500)) offerCleaning = <Alert severity="warning" action={<Button variant="outlined" onClick={doReset} style={{whiteSpace:'nowrap'}}>{t('Reset Client')}</Button>}>
      {t('Since the server is rejecting one or more operations from you, you need to reset your client to continue using SHRIMP. When you do, your unsynced changes will be lost.')}
    </Alert>
    return <Box sx={{maxWidth:800}}>
      <h1>{t('Pending Operations')}</h1>
      {t('There are currently {{count}} unsynced operations that could not yet be saved to the server.', {count: opLog.length})}
      {offerCleaning}
      <table>
        <thead>
          <tr><th>#</th><th>Op</th><th>Details</th><th>Age</th><th>Status</th></tr>
        </thead>
        <tbody>
          {opLog.map((op:idbStoredOp, index:number) => {
            return <tr key={`${index}-${op.id}`}>
              <td>{index+1} ({op.id})</td>
              <td>{op.op}</td>
              <td><Operation op={op} /></td>
              <td>{op.tCreated ? <TimeFromNow timestamp={op.tCreated * 1000} /> : null}</td>
              <td>.</td>
            </tr>
          }).reverse()}

        </tbody>
      </table>
    </Box>
  }

  return (
    <>
      <Box sx={{ alignContent: "start", p: 5 }}>
        <h1>{t('Sync Status')}</h1>
        <table>
          <tbody>
            <tr><td>{t('Last Sync Status')}</td><td>{statusIndicator(lastSyncStatus)}</td></tr>
            <tr><td>{t('Last Sync Timestamp')}</td><td>{timestampIndicator(lastSyncTimestamp)}</td></tr>
            <tr><td>{t('Pending Operations')}</td><td><Alert severity={syncQueueLength ? "warning" : "info"}>{syncQueueLength}</Alert></td></tr>
          </tbody>
        </table>
        {pendingOperations()}
      </Box>
    </>
  )

}

export default observer(Syncing)