import { t } from "i18next"
import { observer } from "mobx-react-lite"
import { ChangeEvent, useState } from "react"
import { useParams } from "../../helper/Helper"
import { useStore } from "../../contexts/store"
import { PodClass } from "../../classes/Pod"
import { generateInstantPassword } from "../../stores/uiStore"
import api from "../../api/api"
import DialogWrapper from "../Elements/DialogWrapper"
import InteractionOpHeader from "../Elements/InteractionOpHeader"
import { UserInfo } from "../../../../types/User"
import { instantUserPwMaxLength, instantUserPwMinLength, notInstantUserPwRegex } from "../../validationConstantsString"
import { alert }from "../../stores/alertStore"


import { Alert, Box, Button, DialogContent, DialogTitle, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import PasswordIcon from '@mui/icons-material/Key'

const ChangePasswordModal = ({handleClose, user}: {handleClose: () => void, user: UserInfo | null}) => {
  const { uiStore, podStore, alertStore } = useStore()
  const { podId } = useParams()
  const deviceWidth = uiStore.deviceWidth
  const isOpen = user ? true : false

  const pod:PodClass|null = podStore.pod
  if (!pod || user === null) return null

  const Title = () => (
    <DialogTitle sx={{padding: 0}}>
      <InteractionOpHeader color={'grey'} interactionOp={'changeUserPassword'} handleClose={handleClose} />
    </DialogTitle>
  )

  const ChangePasswordInput = ({user}: {user: UserInfo}) => {
    const [newPassword, setNewPassword] = useState(generateInstantPassword())
    const [hasError, setHasError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showCloseButton, setShowCloseButton] = useState(false)

    const saveNewPassword = async () => {
      setIsLoading(true)
      const res = await api.changePassword(podId, user.userId, newPassword)
      if (res && res.status === 200) {
        setShowCloseButton(true)
      }
      else {
        alertStore.push(alert(JSON.stringify(res.body?.msg), 'error'))
      }
      setIsLoading(false)
    }

    function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
      const value = event.target.value
      setNewPassword(value)
      const isValid = isValidPassword(value)
      setHasError(!isValid)
    }

    const isValidPassword = (password: string) => {
      // check password length
      if(password.length < instantUserPwMinLength) return false
      if(password.length > instantUserPwMaxLength) return false
      // check if characters are valid
      const hasValidCharacters = password.match(notInstantUserPwRegex) ? false : true
      return hasValidCharacters
    }

    return (
      <Box sx={{display: "grid", overflow: "auto", gridTemplateRows: "max-content auto max-content max-content", height: "100%", margin: "0 20px"}}>
        <Box sx={{margin: "20px 0", padding: "10px 20px", color: "grey"}}>
          {t('changeUserPassword_header')}
        </Box>
        <Box sx={{display: "grid", overflow: "auto", alignContent: "start", paddingRight: "2px"}}>
            <Box sx={{display: "grid", gridTemplateColumns: "50% 50%", gridGap: "10px", margin: "5px 10px"}}>
              <FormControl>
                <OutlinedInput
                  disabled
                  value={user.login}
                  />
                  <FormHelperText sx={{display: "grid", gridTemplateColumns: "max-content max-content", alignItems: "center"}}>
                    <PersonIcon fontSize="small" />
                    {user.userName}
                  </FormHelperText>
              </FormControl>
              <FormControl>
                <OutlinedInput
                  disabled
                  value='********'
                  endAdornment={
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
              {/* new password input on right side*/}
              <Box></Box>
              <FormControl>
                <InputLabel htmlFor="user-new-password">{t('New password')}</InputLabel>
                <OutlinedInput
                  autoFocus
                  disabled={showCloseButton ? true : false}
                  error={!isValidPassword(newPassword)}
                  id={'user-new-password'}
                  label={t('New password')}
                  value={newPassword}
                  onChange={handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <PasswordIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
        </Box>
        {showCloseButton &&
          <Box sx={{padding: "10px"}}>
            <Alert severity="success">{t('Password changed successfully.')}</Alert>
          </Box>
        }
        {hasError &&
          <Box sx={{padding: "10px"}}>
              <Alert severity="error">{t('The password must contain at least {{minLength}} and no more than {{maxLength}} characters. It must not contain any special characters or spaces.', {minLength: instantUserPwMinLength, maxLength: instantUserPwMaxLength})}</Alert>
          </Box>
        }
        <Box sx={{margin: "20px 0", justifySelf: "end"}}>
          {showCloseButton ?
            <Button size="large" onClick={handleClose} variant="contained">{t('Close')}</Button> :
            <Button disabled={hasError || isLoading ? true : false} size="large" onClick={saveNewPassword} variant="contained">{t('Save')}</Button>
          }
        </Box>
      </Box>
    )
  }


  // smartphone view
  if(deviceWidth === "tiny") {
    return (
      <DialogWrapper modus="smartphone" isOpen={isOpen} fullHeight={false} handleClose={handleClose} >
        <Title />
        <DialogContent sx={{ display: "grid", gridTemplateRows: "min-content auto", backgroundColor: "white", padding: 0}}>
          <ChangePasswordInput user={user} />
        </DialogContent>
      </DialogWrapper>
    )
  }
  return (
    <DialogWrapper modus="tablet" isOpen={isOpen} fullHeight={true} handleClose={handleClose} >
      <Title />
      <DialogContent sx={{ display: "grid", gridTemplateRows: "1fr auto", backgroundColor: "white", padding: 0}}>
        <ChangePasswordInput user={user} />
      </DialogContent>
    </DialogWrapper>
  )

}

export default observer(ChangePasswordModal)