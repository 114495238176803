import { useEffect, useState } from "react"
import { t } from "i18next"
import { observer } from "mobx-react-lite"
import { navigate } from "wouter/use-location"
import { useStore } from "../../../contexts/store"
import Thread from "../../Elements/Thread"

import { Box, Button, IconButton } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import BlockquoteBox from "../../Elements/BlockquoteBox"

const ActiveConversation = ({podId, activeConversationId}: {podId: string, activeConversationId: string}) => {
  const { uiStore, podStore } = useStore()
  const nodeId = podStore.getNodeId(activeConversationId)
  const [ isRendered, setIsRendered] = useState(false)
  const interaction = activeConversationId && nodeId ? podStore.getInteraction(nodeId, activeConversationId) : null

  useEffect(() => {
    setIsRendered(true)
  }, [])

  const ActiveConversationHeader = () => (
    <Box sx={{
      alignItems: "center",
      backgroundColor: "#ffd800db",
      borderBottom: "1px solid rgba(63, 67, 80, 0.16)",
      color: "#3b3b3b",
      display: "grid",
      fontSize: "18px",
      gridTemplateColumns: "max-content auto max-content",
      height: (uiStore.deviceWidth === "tiny" || uiStore.deviceWidth === 'smaller') ? "45px" : "56px",
      padding: "0 10px"
    }}>
      <IconButton aria-label="back" onClick={() => resetActiveConversationId()}>
        <ArrowBackIcon sx={{color: "#3b3b3b"}} />
      </IconButton>
      <Box sx={{margin: "0 5px", fontWeight: "450"}}>
        {(uiStore.deviceWidth === "small") &&
          t('Conversation')
        }
      </Box>
      <Button sx={{backgroundColor: "white", fontSize: (uiStore.deviceWidth === "tiny" || uiStore.deviceWidth === 'smaller') ? "10px" : "", "&:hover": {backgroundColor: "aliceblue"} }} onClick={() => navigateToInteraction(nodeId, "comment", activeConversationId)}>
        {t('Jump to the text passage')}
      </Button>
    </Box>
  )

  // show blank page until elements are rendered
  if(isRendered === false) return <ActiveConversationHeader />
  if(interaction === null) return <ActiveConversationHeader />

  const resetActiveConversationId = () => {
    uiStore.setActiveConversation(podId, null)
    const url = `/pod/${podId}/conversations`
    navigate(url, { replace: true })
  }

  const navigateToInteraction = (nodeId: string | null, interactionType: string, interactionId: string | null) => {
    if(nodeId && interactionType && interactionId) {
      const url = `/pod/${podId}/pdf/${nodeId}/${interactionType}/${interactionId}`
      navigate(url)
    }
  }

  return (
    <Box className="overflowWrapper" sx={{alignContent: "start"}}>
      <ActiveConversationHeader />
      <Box sx={{overflowY: "auto"}} id="active-conversation">
        <BlockquoteBox interaction={interaction} />
        <Thread interactionId={activeConversationId} interactionType={interaction.interactionType} nodeId={nodeId} />
      </Box>
    </Box>
  )
}

export default observer(ActiveConversation)