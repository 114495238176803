import { t } from 'i18next'
import React, { useState } from 'react'
import { useStore } from '../../../contexts/store'
import { PDFDownloadLink } from '@react-pdf/renderer'
import AddUserPdfDocument from './AddUserPdfDocument'
import { Box, Button } from '@mui/material'

const AddUserStepperConfirm = () => {
  const [hasDownloaded, setHasDownloaded] = useState(false)
  const { uiStore } = useStore()

  const handleClose = () => {
    uiStore.closeAddUserModal()
    // clear pad and instant user list
    uiStore.updateInstantUserPad('')
    uiStore.deleteInstantUserList()
  }

  return(
    <Box sx={{display: "grid", gridTemplateRows: "max-content auto max-content", height: "100%"}}>
      <Box sx={{margin: "20px 0", padding: "10px 20px", color: "grey"}}>
        {t('addUserStepper_header_completion')}
      </Box>
      <Box sx={{display: "grid", gridGap: "10px", gridTemplateRows: "max-content", gridTemplateColumns: "max-content max-content", marginTop: "10px", justifyContent: "end"}}>
        <PDFDownloadLink style={{
          backgroundColor: "green",
          border: "1px solid #cefac0",
          borderRadius: "5px",
          color: "white",
          fontFamily: "sans-serif",
          fontSize: "20px",
          padding: "10px",
          textDecoration: "none",
        }}
          onClick={() => setHasDownloaded(true)}
          document={<AddUserPdfDocument />} fileName="userlist.pdf">
          {({ blob, url, loading, error }) =>
            loading ? t('Download this file') : t('Download this file')
          }
        </PDFDownloadLink>
        <Button disabled={!hasDownloaded} variant='contained' onClick={handleClose}>
          {t('Close')}
        </Button>
      </Box>
    </Box>
  )
}

export default AddUserStepperConfirm