import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { Box } from "@mui/material"
import { useStore } from "../../contexts/store"
import Message from "../Elements/Message"
import NewMessage from "./NewMessage"
import MessageArea from "./MessageArea"
import { Message as Messagetype, Thread as ThreadType } from "../../../../types/Message"
import { Op_addMessage, Op_addThread } from "../../../../types/Ops"
import { PodClass } from "../../classes/Pod"
import ViewTracker from "./ViewTracker"

const Thread = ({interactionId, interactionType, nodeId}:{interactionId: string | null, interactionType: string, nodeId: string | null}) => {
  const { t } = useTranslation()
  const { podStore, opStore, sessionStore, uiStore } = useStore()
  const { session } = sessionStore
  const pod: PodClass | null = podStore.pod

  // get thread from actual selected comment
  const thread = interactionId ? podStore.getThread(interactionId) : null
  const lastMessageViewed = thread ? podStore.getLastUnseenMessage(thread) : null

  useEffect(() => {
    if(interactionId) {
      // perform initial scrolling
      // if there is an unseen message: scroll to it
      if(lastMessageViewed) {
        document.getElementById(lastMessageViewed)?.scrollIntoView()
      }
      // else: scoll to last message
      else {
        if(thread && thread.messages && thread.messages.length > 0) {
          document.getElementById(thread.messages[thread.messages.length-1].messageId)?.scrollIntoView()
        }
      }
    }
  })

  if(interactionId === null || nodeId === null || pod === null) return null
  const { podId } = pod

  // TODO: check if its allowed to add messages in this thread
  // const addMessageIsAllowed = podStore.pod.isAllowed('addMessage', thread?.threadId)

  // list of thread messages
  const threadMessages: JSX.Element[] = []
  // add thread comments
  if(thread) {
    thread.messages.forEach(message => {
      // add new message label
      if(lastMessageViewed && message.messageId === lastMessageViewed) {
        threadMessages.push(
          <li key={`${message.messageId} new message`}>
            <NewMessage />
          </li>
        )
      }
      // add message
      threadMessages.push(
        <li id={message.messageId} key={message.messageId} style={{scrollMarginTop: "200px"}}>
          <Message content={message} interactionId={interactionId} usergroupId={thread.usergroupId} />
        </li>
      )
    })
  }

  function submitInput(input: string) {
    let threadId = thread?.threadId
    // create new thread, if this is the first reply
    if(thread === null) {
      threadId = `T.${interactionId}` // for pod-public comments, base the threadId on the interactionId
      opStore.doOp({
        op: "addThread",
        podId: podId,
        data: {
          coid: null,
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
          interactionId: interactionId,
          messages: [],
          threadName: "",
          tCreated: dayjs().unix(),
          tModified: dayjs().unix(),
          threadId: threadId,
          userId: session.user.userId,
          userName: podStore.userPseudonym,
        } as ThreadType
      } as Op_addThread)
    }
    // create message inside comment
    opStore.doOp({
      op: "addMessage",
      podId: podId,
      data: {
        usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
        messageId: sessionStore.createUuid(),
        threadId: threadId,
        refMessageId: null,
        coid: null,
        userId: session.user.userId,
        userName: podStore.userPseudonym,
        text: input,
        reactions: {},
        tCreated: dayjs().unix(),
        tModified: dayjs().unix()
      } as Messagetype
    } as Op_addMessage)
  }

  return (
    <ViewTracker type={interactionType} id={interactionId}>
      <Box>
        <ul style={{listStyleType: "none", padding: "5px", margin: 0}}>
          {threadMessages}
        </ul>
        <Box sx={{position: "sticky", bottom: 0, backgroundColor: "white", zIndex: "2"}}>
          <MessageArea placeholder={(uiStore.deviceWidth === "tiny" || uiStore.deviceWidth === 'smaller') ? t('reply')+" ..." : t("Reply to this comment ...")} submitInput={submitInput} value="" key={interactionId} disabled={!(pod.isAllowed('addMessage') && (pod.isAllowed('addThread') || threadMessages.length>1))} />
        </Box>
      </Box>
    </ViewTracker>
  )
}

export default observer(Thread)
