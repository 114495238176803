import { useTranslation } from 'react-i18next'
import { UIEvent, useEffect, useState } from 'react'
import { useParams } from "../../stores/uiStore"
import { observer } from 'mobx-react-lite'
import { useStore } from '../../contexts/store'
import { PodClass } from '../../classes/Pod'
import SyncProblemIcon from '@mui/icons-material/SyncProblem'
import ErrorBoundaryComponent from '../ErrorBoundaryComponent'
import ConfirmDialog from '../Elements/ConfirmDialog'
import { Link } from 'wouter'

import Contents from '../Pod/Contents'
import Activity from '../Pod/Activity/Activity'
import Conversations from '../Pod/Conversation/Conversations'
import ConversationUnreads from '../Pod/Conversation/ConversationUnreads'
import Notes from '../Pod/Notes'
import PodUsers from '../Pod/PodUsers'
import Settings from '../Pod/Settings'

import { Box, FormControl, InputLabel, MenuItem, Link as MuiLink, Select, SelectChangeEvent } from "@mui/material"
import { navigate } from 'wouter/use-location'


const Pod = ({activeTabIndex, activeConversationId}: {activeTabIndex?: string, activeConversationId?: string}) => {
  const { t } = useTranslation()
  const { podId } = useParams()
  const { podStore, uiStore } = useStore()
  const pod:PodClass|null = podStore.pod
  const [ confirmPodReset, setConfirmPodReset ] = useState<null|string>(null)
  const deviceWidth = uiStore.deviceWidth
  const containerPadding = (deviceWidth === "tiny" || deviceWidth === 'smaller') ? 16 : 40

  useEffect(() => {
    if(activeTabIndex) uiStore.setActivePodTab(podId, activeTabIndex)
    // reset show unread conversations
    uiStore.setShowUnreads(false)
  }, [activeTabIndex])

  if (pod === null) return <div>'pod' is null when trying to show pod</div>

  const isAdmin = pod.isAllowed('deletePod', pod.podId)

  const initScrollPos = (tab: string) => {
    const scrollPos = uiStore.getPodScrollPos(podId, tab)
    const podContainer = document.getElementById("pod-tab-scroll-container")
    if(scrollPos !== null && podContainer) {
      podContainer.scroll({
        top: scrollPos
      })
    }
  }

  const podTabs = [
    {
      path: 'contents',
      name: t('Contents'),
    },{
      path: 'activity',
      name: t('Activity'),
    },{
      path: 'conversations',
      name: t('Conversations'),
    },{
      path: 'notes',
      name: t('Notes'),
    },{
      path: 'people',
      name: t('People'),
    },{
      path: 'settings',
      name: isAdmin ? t('Settings') : t('Info'),
    }
  ]

  const activeTabStyle = {
    alignContent: "center",
    backgroundColor: "white",
    borderBottom: "2px solid rgb(108, 108, 211)",
    color: "rgb(108, 108, 211)",
    display: "grid",
    fontSize: "14px",
    fontWeight: "500",
    gridTemplateColumns: "min-content min-content",
    gridGap: "5px",
    padding: "13px"
  }

  const tabStyle = {
    alignContent: "center",
    borderBottom: "2px solid white",
    color: "rgba(0, 0, 0, 0.6)",
    display: "grid",
    fontSize: "14px",
    fontWeight: "500",
    gridTemplateColumns: "min-content min-content",
    gridGap: "5px",
    padding: "13px"
  }

  // if a conversation was previously active, navigate there
  function generateUrl(tab: string) {
    const activeConversation = uiStore.getActiveConversation(podId)
    const url = `/pod/${podId}/${tab}`
    if(tab === "conversations" && activeConversation) return `${url}/${activeConversation}`
    return url
  }

  const Menu = () => {
    // show selection menu on small screens
    if(deviceWidth === "tiny" || deviceWidth === 'smaller') return <SeclectionMenu />
    // else show tab menu
    return (
      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "2px",
        margin: "0 8px"
      }}>
        {
        podTabs.map((tab, i) => (<Link key={tab.path} to={generateUrl(tab.path)}>
          <MuiLink underline="none" sx={ activeTabIndex === tab.path ? activeTabStyle : tabStyle } style={{textTransform: 'uppercase'}}>
            {tab.name}
            {tab.path === "conversations" &&
              <ConversationUnreads />
            }
          </MuiLink>
        </Link>))
        }
      </Box>
    )
  }

  const SeclectionMenu = () => (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="select-pod-menu-label">{t('Menu')}</InputLabel>
        <Select
          labelId="select-pod-menu-label"
          id="select-pod-menu"
          value={activeTabIndex}
          label="Menu"
          onChange={(event: SelectChangeEvent) => {if(event.target && event.target.value) navigate(generateUrl(event.target.value))}}
        >
          {
          podTabs.map((tab, i) => (<MenuItem key={tab.path} value={tab.path}>{tab.name}</MenuItem>))
          }
        </Select>
      </FormControl>
    </Box>
  )

  const Main = () => {
    if(activeTabIndex === "activity") return (
      <ErrorBoundaryComponent>
        <Activity initScrollPos={initScrollPos} />
      </ErrorBoundaryComponent>
    )
    if(activeTabIndex === "conversations") return (
      <ErrorBoundaryComponent>
        <Conversations activeConversationId={activeConversationId} />
      </ErrorBoundaryComponent>
    )
    if(activeTabIndex === "notes") return (
      <ErrorBoundaryComponent>
        <Notes pod={pod} initScrollPos={initScrollPos} />
      </ErrorBoundaryComponent>
    )
    if(activeTabIndex === "people") return (
      <ErrorBoundaryComponent>
        <PodUsers initScrollPos={initScrollPos} />
      </ErrorBoundaryComponent>
    )
    if(activeTabIndex === "settings") return (
      <ErrorBoundaryComponent>
        <Settings initScrollPos={initScrollPos} />
      </ErrorBoundaryComponent>
    )
    return (
      <ErrorBoundaryComponent>
        <Contents pod={pod} initScrollPos={initScrollPos} />
      </ErrorBoundaryComponent>
    )
  }

  const OutOfSync = () => {
    if(pod.outOfSync) {
      return (
        <Box
          style={{float:'right'}}
          title={t('This pod seems to be out of sync. It is recommended that you reset it. Click for more.')}
          onClick={() => {setConfirmPodReset(pod.podId) }}
        >
          <SyncProblemIcon color='warning' />
        </Box>
      )
    }
    return null
  }

  const updateScrollPos = (event: UIEvent<HTMLDivElement>) => {
    if(event && event.currentTarget && event.currentTarget.scrollTop !== undefined && activeTabIndex) {
      const scrollTop = event.currentTarget.scrollTop
      uiStore.setPodScrollPos(podId, activeTabIndex, scrollTop)
    }
  }

  return (
    <ErrorBoundaryComponent>
      <Box className="overflowWrapper" sx={{
          display: "grid",
          gridTemplateRows: "max-content auto",
      }}>
        {/* Header */}
        <Box sx={{backgroundColor: "white"}}>
          <Box sx={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: `${1280 + (2*containerPadding)}px`,
            paddingTop: `${(deviceWidth === 'medium' || deviceWidth === 'large') ? 40 : 0}px`,
            paddingLeft: `${containerPadding}px`,
            paddingRight: `${containerPadding}px`,
            marginBottom: "5px"
          }}>
              <OutOfSync />
              {(deviceWidth === "tiny" || deviceWidth === 'smaller') ?
                <Box style={{color: "rgba(0, 0, 0, 0.87)", fontSize: "20px", fontWeight: "700", margin: "5px 0 15px 0"}}>
                  {pod.name}
                </Box>
              :
                <h2 style={{color: "rgba(0, 0, 0, 0.87)", fontSize: "24px", fontWeight: "700"}}>
                  {pod.name}
                </h2>
              }
              <Menu />
          </Box>
        </Box>

        {/* Main */}
        <Box className="overflowWrapper" sx={{backgroundColor: "white"}}>
          <Box className="overflowWrapper" sx={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: `${1280 + (2*containerPadding)}px`,
            paddingLeft: `${containerPadding}px`,
            paddingRight: `${containerPadding}px`,
            marginBottom: (deviceWidth !== "tiny" && deviceWidth !== 'smaller') ? "10px" : "5px",
            alignItems: 'start',
          }}>
            <Box id="pod-tab-scroll-container" onScroll={(event) => updateScrollPos(event)} className="overflowWrapper" sx={{ height: "100%", p: (deviceWidth !== "tiny" && deviceWidth !== 'smaller') ? "12px 12px 0 12px" : "0 1px"}}>
              <Main />
            </Box>
          </Box>
        </Box>

      </Box>
      <ConfirmDialog
        title={t('Pod Out of Sync')}
        info={t('The Pod seems to be out of sync. To make sure') as string}
        open={Boolean(confirmPodReset)}
        handleClose={() => { setConfirmPodReset(null); }}
        onOk={() => { if (confirmPodReset) podStore.resetPod(confirmPodReset) }}
      />
    </ErrorBoundaryComponent>
  )

}

export default observer(Pod)
