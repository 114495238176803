import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { useStore } from "../../contexts/store"
import InteractionIcon from "./InteractionIcon"
import { Box, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'

const InteractionOpHeader = ({ color, interactionOp, interactionId, handleClose }: { color: string, interactionOp: string | null, interactionId?: string | undefined | null, handleClose: () => void }) => {
  const { t } = useTranslation()
  const { uiStore } = useStore()
  const previewColor = uiStore.previewColor

  const interactionType = (op: string | null) => {
    if(op === "addAnnotation" || op === "editAnnotation") return "annotation"
    if(op === "addComment" || op === "editComment") return "comment"
    if(op === "addLink" || op === "editLink") return "link"
    if(op === "addTagging" || op === "editTagging") return "tagging"
    if(op === "addWeblink" || op === "editWeblink") return "weblink"
    if(op === "addEmotion" || op === "editEmotion") return "emotion"
    if(op === "addReadingQuestion" || op === "editReadingQuestion") return "readingQuestion"
    if(op === "addUserToPod") return "addUserToPod"
    if(op === "changeUserPassword") return "changeUserPassword"
    return null
  }

  return (
    <Box sx={{
      alignItems: "center",
      backgroundColor: (previewColor && interactionId && interactionId === previewColor.interactionId) ? previewColor.color : color,
      color: "white",
      display: "grid",
      gridGap: "15px",
      gridTemplateColumns: "auto min-content",
      padding: "10px 20px"
    }}>
      <Box sx={{
        display: "grid",
        gridGap: "15px",
        gridTemplateColumns: "min-content auto",
        alignItems: "center"
    }}>
        <InteractionIcon interactionType={interactionType(interactionOp)} />
        {interactionOp === "addAnnotation" && t('Add Annotation')}
        {interactionOp === "addComment" && t('Add Comment')}
        {interactionOp === "addLink" && t('Add Link')}
        {interactionOp === "addTagging" && t('Add Tag')}
        {interactionOp === "addWeblink" && t('Add Weblink')}
        {interactionOp === "addEmotion" && t('Add Emotion')}
        {interactionOp === "addReadingQuestion" && t('Add ReadingQuestion')}
        {interactionOp === "editAnnotation" && t('Edit Annotation')}
        {interactionOp === "editComment" && t('Edit Comment')}
        {interactionOp === "editLink" && t('Edit Link')}
        {interactionOp === "editTagging" && t('Edit Tag')}
        {interactionOp === "editWeblink" && t('Edit Weblink')}
        {interactionOp === "editEmotion" && t('Edit Emotion')}
        {interactionOp === "editReadingQuestion" && t('Edit ReadingQuestion')}
        {interactionOp === "addUserToPod" && `${t('addUserToPod')}`}
        {interactionOp === "changeUserPassword" && `${t('changeUserPassword')}`}
      </Box>
      <IconButton aria-label="settings" onClick={handleClose}>
        <CloseIcon sx={{color: "white"}} />
      </IconButton>
    </Box>
  )
}

export default observer(InteractionOpHeader)